import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { REDUX_STATUS } from 'lib/constants';
import { MessagePropType } from 'lib/propTypes';
import { buildRoutePath } from 'lib/routerHelpers';
import { FACILITATOR_PARTICIPANTS_SHOW_PATH } from 'lib/routerPaths';
import { DELETE_MESSAGE, RECALL_MESSAGE, RESET_MESSAGE } from 'store/messages/actions';
import { Button, Spinner } from 'react-bootstrap';
import Confirmation from 'components/shared/Modal/Confirmation';
import MultilineText from 'components/shared/MultilineText';
import SanyasIcon from 'components/shared/SanyasIcon';
import { formatDate } from '../utils';

function Message({ message, userId, onEdit }) {
  const currentUser = useSelector((state) => state.currentUser);
  const stateMessage = useSelector((state) => state.messages.message);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const dispatch = useDispatch();
  const { status, error } = stateMessage.id === message.id ? stateMessage : {};
  const messagesUserId = userId || currentUser.id;

  const handleConfirmDeleteClose = useCallback(() => {
    setConfirmDeleteOpen(false);
  }, []);

  const handleRecallMessage = () => {
    dispatch(RECALL_MESSAGE.request(message.id));
  };

  const handleDeleteMessage = () => {
    dispatch(DELETE_MESSAGE.request(message.id));
  };

  const handleResetMessage = () => {
    dispatch(RESET_MESSAGE.action());
  };

  return (
    <>
      <div className={`col-11 ${message.fromUser.id === messagesUserId ? 'offset-1 rounded-start-4 messages--current-user' : 'rounded-end-4 bg-light'} ${error ? '' : 'mb-2'} border rounded-bottom-4 py-2 px-3`}>
        <p className="fw-semibold">
          {(message.fromUser.id !== currentUser.id && !message.fromUser.isFacilitator) ? (
            <Link
              to={buildRoutePath(FACILITATOR_PARTICIPANTS_SHOW_PATH, { groupMembershipId: message.fromUser.groupMembershipId })}
              className="btn-plain"
            >
              {`${message.fromUser.firstName} ${message.fromUser.lastName}`}
            </Link>
          ) : (
            <span>{`${message.fromUser.firstName} ${message.fromUser.lastName}`}</span>
          )}
          <small className="ps-2">{formatDate(message.createdAt)}</small>
        </p>

        <MultilineText text={message.body} />

        {message.fromUser.id === currentUser?.id && (
          <>
            {message.state === 'pending' && (
              <div key={`${message.id}-pending`} className="text-end">
                <Button variant="plain" size="sm" onClick={handleRecallMessage} disabled={status === REDUX_STATUS.PENDING}>
                  Recall
                  {status === REDUX_STATUS.PENDING && <Spinner size="sm" className="ms-1" animation="border" role="status" />}
                </Button>
              </div>
            )}

            {['recalled', 'delivered'].includes(message.state) && (
              <div key={`${message.id}-edit`} className="text-end">
                <Button variant="plain" size="sm" onClick={onEdit}>
                  Edit
                </Button>

                {message.state === 'recalled' && (
                  <>
                    <Button variant="plain" size="sm" onClick={() => setConfirmDeleteOpen(true)}>
                      Delete
                    </Button>

                    <Confirmation
                      title="Delete Message?"
                      open={confirmDeleteOpen}
                      close={handleConfirmDeleteClose}
                      text="Are you sure you want to delete this message? This action cannot be undone."
                      confirmText="Delete"
                      confirmAction={handleDeleteMessage}
                      cancelText={null}
                      delay={800}
                    />
                  </>
                )}
              </div>
            )}
          </>
        )}
      </div>

      {error && (
        <div className="d-flex align-items-center mb-2">
          <Button variant="plain" size="sm" onClick={handleResetMessage}>
            <SanyasIcon name="xmark" className="text-danger" />
          </Button>
          <small className="text-danger">{error.details?.message || error.message || error}</small>
        </div>
      )}
    </>
  );
}

Message.defaultProps = {
  userId: null,
  onEdit: () => {},
};

Message.propTypes = {
  message: MessagePropType.isRequired,
  userId: PropTypes.number,
  onEdit: PropTypes.func,
};

export default Message;
