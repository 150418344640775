import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { CREATE_REPLY } from 'store/messages/actions';
import { Button, Spinner } from 'react-bootstrap';
import TextareaField from 'components/shared/FormFields/TextareaField';
import ErrorMessage from 'components/shared/ErrorMessage';
import { canSendMessage } from '../utils';

const FormSchema = yup.object().shape({
  message: yup.string().required('Required'),
});

function ReplyArea({ disabled }) {
  const currentUserId = useSelector((state) => state.currentUser?.id);
  const { conversation, submitReplyError } = useSelector((state) => state.messages.show);
  const message = useSelector((state) => state.messages.message);
  const dispatch = useDispatch();
  const { hasMessageAccess, reason } = canSendMessage({ currentUserId, conversation });
  const canReply = (!conversation?.isAnnouncement && conversation?.messages?.length > 0 && (!message.data || ['delivered', 'read'].includes(message.data?.state)));

  const createReply = (values, actions) => {
    dispatch(CREATE_REPLY.request(values, { actions }));
  };

  if (!canReply) {
    return null;
  }

  if (!hasMessageAccess) {
    return <p className="text-small text-secondary">{reason}</p>;
  }

  return (
    <Formik
      initialValues={{ message: '' }}
      validationSchema={FormSchema}
      onSubmit={createReply}
    >
      {({ dirty, isSubmitting, resetForm }) => (
        <Form className="mt-4">
          <ErrorMessage error={{ message: submitReplyError }} />

          <TextareaField name="message" placeholder="Type reply here" rows="10" hideLabel required disabled={disabled || isSubmitting} />

          <div className="d-flex justify-content-end">
            <Button variant="primary" type="submit" className="order-2" disabled={disabled || isSubmitting}>
              Send
              {isSubmitting && <Spinner size="sm" className="ms-1" animation="border" role="status" />}
            </Button>
            <Button variant="plain" type="button" className="order-1 me-1" disabled={disabled || !dirty || isSubmitting} onClick={resetForm}>
              Cancel
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

ReplyArea.defaultProps = {
  disabled: false,
};

ReplyArea.propTypes = {
  disabled: PropTypes.bool,
};

export default ReplyArea;
