import { takeLatest, put } from 'redux-saga/effects';
import { fetchGet, handleSimpleFetchError } from 'lib/apiHelpers';
import { GET_DASHBOARD } from 'store/superFacilitator/actions';

export const effects = [];

export default function* sagas() {
  yield takeLatest(GET_DASHBOARD.REQUEST, function* getDashboard() {
    try {
      const { data } = yield fetchGet('/api/super_facilitator/dashboard');
      yield put(GET_DASHBOARD.success(data));
    } catch (err) {
      yield handleSimpleFetchError(err, GET_DASHBOARD);
    }
  });
}
