import React, { createRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useRouteMatch } from 'react-router-dom';
import { REDUX_STATUS } from 'lib/constants';
import useSessionStorage from 'lib/hooks/useSessionStorage';
import { FACILITATOR_GROUP_LIVE_FEED_PATH, FACILITATOR_LIVE_FEED_PATH } from 'lib/routerPaths';
import { GET_FACILITATOR_LIVE_FEED } from 'store/facilitatorDashboard/actions';
import { GET_LIVE_FEED } from 'store/groupShow/actions';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Spinner } from 'react-bootstrap';
import ErrorMessage from 'components/shared/ErrorMessage';
import LiveFeedItem from 'components/facilitator/LiveFeed/LiveFeedItem';
import Skeleton from './Skeleton';
import './styles.scss';

const FEED_TYPES = {
  group: { value: 'group', label: 'Group', context: 'group' },
  personalized: { value: 'personalized', label: 'Personalized', context: 'group' },
  global: { value: 'global', label: 'Global', context: 'facilitator' },
  global_personalized: { value: 'global_personalized', label: 'Personalized', context: 'facilitator' },
  global_facilitator: { value: 'global_facilitator', label: 'Facilitator', context: 'facilitator' },
};

const CONTEXT_DATA = {
  [FACILITATOR_GROUP_LIVE_FEED_PATH]: {
    store: 'groupShow',
    context: 'group',
    ACTION: GET_LIVE_FEED,
  },
  [FACILITATOR_LIVE_FEED_PATH]: {
    store: 'facilitatorDashboard',
    context: 'facilitator',
    ACTION: GET_FACILITATOR_LIVE_FEED,
  },
};

function LiveFeed() {
  const { id: groupId } = useParams();
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const { store, context, ACTION } = CONTEXT_DATA[path] || {};
  const { allIds: notifications, status, error } = useSelector((state) => state[store]?.liveFeed) || {};
  const localFeedTypes = Object.values(FEED_TYPES).filter((t) => t.context === context);
  const [feedData, setFeedData] = useSessionStorage(`live-feed-${context}`, { feedType: localFeedTypes[0]?.value, taskType: '' });
  const loading = status === REDUX_STATUS.PENDING;

  useEffect(() => {
    const { feedType, taskType } = feedData;
    if (ACTION) {
      dispatch(ACTION.request({ groupId, feedType, taskType }));
    }
  }, [ACTION, groupId, feedData, dispatch]);

  function handleFeedDataChange(event) {
    const { name, value } = event.target;
    setFeedData({ ...feedData, [name]: value });
  }

  return (
    <>
      <ErrorMessage error={error} />

      <div className="row align-items-center mb-4">
        <div className="col-md-3">
          <select className="form-select" name="feedType" id="feed-type" aria-label="Notifications" value={feedData.feedType} onChange={handleFeedDataChange}>
            {localFeedTypes.map(({ value, label }) => (
              <option key={value} value={value}>{label}</option>
            ))}
          </select>
        </div>
        <div className="col-md-3">
          <select className="form-select" name="taskType" id="task-type" aria-label="Types" value={feedData.taskType} onChange={handleFeedDataChange}>
            <option value="">All</option>
            <option value="SubGroupDiscussionTask">Pod discussions</option>
            <option value="JournalTask">Journals</option>
          </select>
        </div>
        <div className="col-1">
          {loading && <Spinner variant="primary" size="sm" className="ms-1" animation="border" role="status" />}
        </div>
      </div>

      {(loading && notifications?.length === 0) && (
        <div className="col-md-8">
          <Skeleton />
        </div>
      )}

      {(!loading && notifications?.length === 0) && (
        <p className="text-secondary">
          No notifications found
        </p>
      )}

      {notifications?.length > 0 && (
        <div className="col-md-8">
          <TransitionGroup key="notifications" component={null}>
            {notifications.map((notificationId) => {
              const nodeRef = createRef(null);
              return (
                <CSSTransition
                  key={notificationId}
                  nodeRef={nodeRef}
                  timeout={250}
                  classNames="notification"
                >
                  <LiveFeedItem innerRef={nodeRef} key={notificationId} notificationId={notificationId} />
                </CSSTransition>
              );
            })}
          </TransitionGroup>
        </div>
      )}
    </>
  );
}

export default LiveFeed;
