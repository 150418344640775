import React from 'react';
import PropTypes from 'prop-types';
import { SIZES } from 'lib/constants';
import { ComponentPropType } from 'lib/propTypes';
import FieldWrapper from './FieldWrapper';
import InputGroup from './InputGroup';
import Input from './Input';

/* eslint-disable react/jsx-props-no-spreading */

function InputField(props) {
  const { id, name, label, type, helpText, infoText, size, hideLabel, inline, required, initialValidation, showTooltip, className, onFieldChange, onFieldBlur, addOnStart, addOnEnd, ...inputProps } = props;

  return (
    <FieldWrapper
      id={id}
      name={name}
      label={label}
      helpText={helpText}
      infoText={infoText}
      size={size}
      type={type}
      hideLabel={hideLabel}
      inline={inline}
      showTooltip={showTooltip}
      required={required}
      initialValidation={initialValidation}
      onFieldChange={onFieldChange}
      onFieldBlur={onFieldBlur}
      className={className}
    >
      <InputGroup
        addOnStart={addOnStart}
        addOnEnd={addOnEnd}
      >
        <Input
          name={name}
          type={type}
          size={size}
          required={required}
          {...inputProps}
        />
      </InputGroup>
    </FieldWrapper>
  );
}

InputField.defaultProps = {
  id: null,
  label: null,
  type: 'text',
  helpText: null,
  infoText: null,
  size: SIZES.md,
  hideLabel: false,
  inline: false,
  required: false,
  initialValidation: null,
  showTooltip: false,
  className: null,
  onFieldChange: null,
  onFieldBlur: null,
  addOnStart: null,
  addOnEnd: null,
};

InputField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  type: PropTypes.oneOf([
    'text', 'email', 'number', 'url', 'tel', 'file',
  ]),
  helpText: PropTypes.string,
  infoText: PropTypes.string,
  size: PropTypes.oneOf(Object.values(SIZES)),
  hideLabel: PropTypes.bool,
  inline: PropTypes.bool,
  required: PropTypes.bool,
  initialValidation: PropTypes.string,
  showTooltip: PropTypes.bool,
  onFieldChange: PropTypes.func,
  onFieldBlur: PropTypes.func,
  addOnStart: PropTypes.oneOfType([PropTypes.string, ComponentPropType]),
  addOnEnd: PropTypes.oneOfType([PropTypes.string, ComponentPropType]),
  className: PropTypes.string,
};

export default InputField;
