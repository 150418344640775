import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { UPDATE_CONVERSATION } from 'store/messages/actions';
import { Button, Spinner } from 'react-bootstrap';
import SanyasIcon from 'components/shared/SanyasIcon';

const FormSchema = Yup.object().shape({
  subject: Yup.string().required().label('Subject'),
});

function SubjectField({ className, onClose }) {
  const { conversation } = useSelector((state) => state.messages.show);
  const dispatch = useDispatch();

  const handleUpdate = useCallback((values, actions) => {
    dispatch(UPDATE_CONVERSATION.request(values, { formikActions: actions, onClose }));
  }, [onClose, dispatch]);

  return (
    <Formik
      initialValues={{ ...conversation }}
      onSubmit={handleUpdate}
      validationSchema={FormSchema}
    >
      {({ errors, isSubmitting, resetForm }) => (
        <Form className={className} style={{ marginLeft: -4 }}>
          <label htmlFor="subject" className="sr-only">
            Subject
          </label>
          <div className="focus-group position-relative">
            <Field
              name="subject"
              type="text"
              className={`form-control ${errors.subject ? 'is-invalid' : ''} px-2 py-1 fw-semibold`}
              style={{ letterSpacing: 0.6 }}
              maxLength="255"
              disabled={isSubmitting}
              autoFocus
            />

            {isSubmitting && (
              <div className="position-absolute top-50 end-0 translate-middle-y me-2">
                <Spinner size="sm" animation="border" role="status" />
              </div>
            )}

            {errors.subject && (
              <div
                className="position-absolute invalid-feedback d-block rounded bg-white"
                style={{ '--bs-bg-opacity': 0.8 }}
              >
                {errors.subject}
              </div>
            )}

            <div className="focus-item position-absolute z-1 top-100 end-0 mt-1 bg-white">
              <Button variant="outline-primary" size="sm" className="sm shadow-sm" type="submit" disabled={isSubmitting}>
                <SanyasIcon name="checkmark" />
              </Button>
              <Button variant="light" size="sm" className="ms-1 shadow-sm" disabled={isSubmitting} onClick={() => { onClose(); resetForm(); }}>
                <SanyasIcon name="xmark" />
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

SubjectField.defaultProps = {
  className: '',
  onClose: () => {},
};

SubjectField.propTypes = {
  className: PropTypes.string,
  onClose: () => {},
};

export default SubjectField;
