import { fetchDelete, fetchGet, fetchPatch, fetchPost, handleSimpleFetchError } from 'lib/apiHelpers';
import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { waitFor } from 'sagas/sagas';
import { SET_NOTIFICATION } from 'store/flashNotifications/actions';
import {
  CREATE_REPLY, DELETE_MESSAGE, GET_CONVERSATION, GET_MESSAGE, GET_MESSAGES, GET_SUB_GROUPS, GET_TOTAL_UNREAD_MESSAGES,
  MARK_CONVERSATION_AS_READ, RECALL_MESSAGE, RESEND_MESSAGE, RESET_COMPOSE, RESET_MESSAGE, SEND_MESSAGES,
  UPDATE_CONVERSATION, UPDATE_MESSAGE, UPDATE_TOTAL_UNREAD_MESSAGES,
} from 'store/messages/actions';

export default function* sagas() {
  yield takeLatest(GET_MESSAGES.REQUEST, function* getMessages({ payload: { userId, page } }) {
    const { messages: { list } } = yield select();

    if (list.page >= page && list.userId === userId) {
      yield put(GET_MESSAGES.success(null, { cached: true }));
      return;
    }

    try {
      const result = yield fetchGet('/api/facilitator/conversations', { userId, page: (page || 1), perPage: 25 });
      yield put(GET_MESSAGES.success(result, { cached: false }));
    } catch (err) {
      yield handleSimpleFetchError(err, GET_MESSAGES);
    }
  });

  yield takeLatest([GET_MESSAGES.SUCCESS, MARK_CONVERSATION_AS_READ.SUCCESS], function* updateTotalUnreadMessages() {
    const { currentUser, messages: { list } } = yield select();
    if (list.userId === currentUser.id) {
      yield put(UPDATE_TOTAL_UNREAD_MESSAGES.action({ userId: currentUser.id }));
    } else {
      yield put(GET_TOTAL_UNREAD_MESSAGES.request());
    }
  });

  yield takeLatest(GET_CONVERSATION.REQUEST, function* getConversation({ payload: { conversationId, userId } }) {
    yield call(waitFor, (state) => state.currentUser); // ensure currentUser is loaded
    const { currentUser } = yield select();

    try {
      const requestParams = (userId) ? { userId } : {};
      const result = yield fetchGet(`/api/facilitator/conversations/${conversationId}`, requestParams);
      yield put(GET_CONVERSATION.success(result));

      const unreadCount = result.conversation.messages.filter((m) => m.toUser.id === currentUser?.id && !m.readAt).length;
      if (unreadCount > 0) {
        yield put(MARK_CONVERSATION_AS_READ.request({ conversationId }));
      }
    } catch (err) {
      yield handleSimpleFetchError(err, GET_CONVERSATION);
    }
  });

  yield takeLatest(MARK_CONVERSATION_AS_READ.REQUEST, function* markConversationAsRead({ payload: { conversationId } }) {
    try {
      const { messageIds } = yield fetchPost(`/api/facilitator/conversations/${conversationId}/mark_as_read`, {});
      yield call(waitFor, (state) => state.messages.list.conversations?.[conversationId]);
      yield put(MARK_CONVERSATION_AS_READ.success({ conversationId, messageIds }));
    } catch (err) {
      yield handleSimpleFetchError(err, MARK_CONVERSATION_AS_READ);
    }
  });

  yield takeLatest(GET_SUB_GROUPS.REQUEST, function* getSubGroups() {
    const { messages: { compose: { availableSubGroups } } } = yield select();
    if (availableSubGroups) {
      yield put(GET_SUB_GROUPS.success(null, { cached: true }));
    } else {
      try {
        const result = yield fetchGet('/api/facilitator/conversations/sub_groups_for_create', {});
        yield put(GET_SUB_GROUPS.success(result, { cached: false }));
      } catch (err) {
        yield handleSimpleFetchError(err, GET_SUB_GROUPS);
      }
    }
  });

  yield takeLatest(GET_MESSAGE.REQUEST, function* refreshMessage({ payload }) {
    try {
      const { data } = yield fetchGet(`/api/facilitator/messages/${payload}`);
      yield put(GET_MESSAGE.success(data));
    } catch (err) {
      yield handleSimpleFetchError(err, GET_MESSAGE);
    }
  });

  yield takeLatest(SEND_MESSAGES.REQUEST, function* sendMessages({ payload: { recipients, subject, message, isFlagged }, meta }) {
    try {
      const { data } = yield fetchPost(
        '/api/facilitator/conversations',
        {
          recipients: { groupMembershipIds: recipients },
          messageSubject: subject,
          messageBody: message,
          isFlagged,
        },
      );
      yield delay(800);
      yield put(SEND_MESSAGES.success(data));
      yield put(RESET_COMPOSE.action());
    } catch (err) {
      yield put(SET_NOTIFICATION.action({
        message: 'The message could not be sent. Please ensure all fields are filled in and try again.',
        type: 'error',
        timeout: 0,
      }));
      yield call(meta.actions.setSubmitting, false);
      yield handleSimpleFetchError(err, SEND_MESSAGES);
    }
  });

  yield takeLatest(CREATE_REPLY.REQUEST, function* createReply({ payload: { message }, meta }) {
    const { messages: { show: { conversation } } } = yield select();
    try {
      const { data } = yield fetchPost(`/api/facilitator/conversations/${conversation.id}/reply`, { messageBody: message });
      yield delay(800);
      yield put(CREATE_REPLY.success(data));
      yield call(meta.actions.resetForm);
    } catch (err) {
      yield handleSimpleFetchError(err, CREATE_REPLY);
    } finally {
      yield call(meta.actions.setSubmitting, false);
    }
  });

  yield takeLatest(UPDATE_CONVERSATION.REQUEST, function* updateSubject({ payload, meta }) {
    try {
      const { data } = yield fetchPatch(
        `/api/facilitator/conversations/${payload.id}`,
        payload,
      );
      yield delay(400);
      yield put(UPDATE_CONVERSATION.success(data));
    } catch (err) {
      yield handleSimpleFetchError(err, UPDATE_CONVERSATION);
      yield put(SET_NOTIFICATION.action({
        message: 'Could not update the conversation. Please reload the page and try again.',
        type: 'error',
      }));
    } finally {
      if (meta?.formikActions?.setSubmitting) {
        yield call(meta.formikActions.setSubmitting, false);
      }
      if (meta?.onClose) {
        yield call(meta.onClose);
      }
    }
  });

  yield takeLatest(GET_TOTAL_UNREAD_MESSAGES.REQUEST, function* getTotalUnreadMessages() {
    try {
      const { totalUnreadMessages } = yield fetchGet('/api/facilitator/conversations/total_unread_messages');
      yield put(GET_TOTAL_UNREAD_MESSAGES.success(totalUnreadMessages));
    } catch (err) {
      yield handleSimpleFetchError(err, GET_TOTAL_UNREAD_MESSAGES);
    }
  });

  yield takeLatest(RECALL_MESSAGE.REQUEST, function* recallMessage({ payload }) {
    try {
      const { data } = yield fetchPatch(`/api/facilitator/messages/${payload}/recall`);
      yield delay(800);
      yield put(RECALL_MESSAGE.success(data));
      yield put(RESET_MESSAGE.action());
    } catch (err) {
      yield put(GET_MESSAGE.request(payload));
      yield handleSimpleFetchError(err, RECALL_MESSAGE);
      yield put(SET_NOTIFICATION.action({
        message: 'Could not recall the message.',
        type: 'error',
      }));
    }
  });

  yield takeLatest(RESEND_MESSAGE.REQUEST, function* resendMessage({ payload, meta }) {
    try {
      const { data } = yield fetchPatch(`/api/facilitator/messages/${payload.id}/resend`, payload);
      yield delay(800);
      yield put(RESEND_MESSAGE.success(data));
      yield put(RESET_MESSAGE.action());
    } catch (err) {
      yield put(GET_MESSAGE.request(payload.id));
      yield handleSimpleFetchError(err, RESEND_MESSAGE);
      yield call(meta.actions.setSubmitting, false);
      yield put(SET_NOTIFICATION.action({
        message: 'Could not resend the message.',
        type: 'error',
      }));
    }
  });

  yield takeLatest(UPDATE_MESSAGE.REQUEST, function* resendMessage({ payload, meta }) {
    try {
      const { data } = yield fetchPatch(`/api/facilitator/messages/${payload.id}`, payload);
      yield delay(800);
      yield put(UPDATE_MESSAGE.success(data));
      yield put(RESET_MESSAGE.action());
    } catch (err) {
      yield put(GET_MESSAGE.request(payload.id));
      yield handleSimpleFetchError(err, UPDATE_MESSAGE);
      yield call(meta.actions.setSubmitting, false);
      yield put(SET_NOTIFICATION.action({
        message: 'Could not update the message.',
        type: 'error',
      }));
    }
  });

  yield takeLatest(DELETE_MESSAGE.REQUEST, function* deleteMessage({ payload }) {
    try {
      yield fetchDelete(`/api/facilitator/messages/${payload}`);
      yield delay(800);
      yield put(DELETE_MESSAGE.success(payload));
    } catch (err) {
      yield put(GET_MESSAGE.request(payload));
      yield handleSimpleFetchError(err, DELETE_MESSAGE);
      yield put(SET_NOTIFICATION.action({
        message: 'Could not cancel the message.',
        type: 'error',
      }));
    }
  });
}
