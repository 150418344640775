import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { UserPropType } from 'lib/propTypes';
import { FACILITATOR_CONVERSATION_PATH, FACILITATOR_PARTICIPANTS_CONVERSATION_PATH } from 'lib/routerPaths';
import { GET_CONVERSATION, CLEAR_SHOW_PAGE } from 'store/messages/actions';
import Spinner from 'components/shared/Spinner';
import ErrorMessage from 'components/shared/ErrorMessage';
import GroupConversation from 'components/shared/Messages/Show/GroupConversation';
import OneOnOneConversation from 'components/shared/Messages/Show/OneOnOneConversation';
import ConversationHeader from 'components/shared/Messages/Show/ConversationHeader';
import ReplyArea from 'components/shared/Messages/Show/ReplyArea';
import 'components/shared/Messages/styles.scss';

function MessagesShowPage({ user }) {
  const { path, params: { conversationId } } = useRouteMatch();
  const { conversation, fetchError } = useSelector((state) => state.messages.show);
  const dispatch = useDispatch();
  const [editingMessage, setEditingMessage] = useState(false);

  useEffect(() => {
    if ((path === FACILITATOR_PARTICIPANTS_CONVERSATION_PATH && user?.id) || (path === FACILITATOR_CONVERSATION_PATH && !user?.id)) {
      dispatch(GET_CONVERSATION.request({ conversationId, userId: user?.id }));
    }
    return () => dispatch(CLEAR_SHOW_PAGE.action());
  }, [conversationId, user?.id, path, dispatch]);

  if (!conversation && !fetchError) {
    return <Spinner message="Loading..." />;
  }

  if (fetchError) {
    return <ErrorMessage error={fetchError} className="mt-3" />;
  }

  return (
    <div className="card border mt-3 mx-4">
      <div className="card-header bg-white">
        <ConversationHeader conversation={conversation} />
      </div>

      <div className="card-body">
        { !conversation.isAnnouncement
            && <OneOnOneConversation conversation={conversation} user={user} isEditing={editingMessage} onEditMessage={(value) => setEditingMessage(value)} />}

        { conversation.isAnnouncement
            && <GroupConversation conversation={conversation} />}

        <ReplyArea disabled={editingMessage} />
      </div>
    </div>
  );
}

MessagesShowPage.defaultProps = {
  user: null,
};

MessagesShowPage.propTypes = {
  user: UserPropType,
};

export default MessagesShowPage;
