import React from 'react';

function Compass() {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M12 22.524C14.5859 22.524 17.0658 21.4968 18.8943 19.6683C20.7228 17.8398 21.75 15.3598 21.75 12.774C21.75 10.1881 20.7228 7.70818 18.8943 5.8797C17.0658 4.05122 14.5859 3.02399 12 3.02399C9.41414 3.02399 6.93419 4.05122 5.10571 5.8797C3.27723 7.70818 2.25 10.1881 2.25 12.774C2.25 15.3598 3.27723 17.8398 5.10571 19.6683C6.93419 21.4968 9.41414 22.524 12 22.524ZM12 24.024C14.9837 24.024 17.8452 22.8387 19.955 20.7289C22.0647 18.6192 23.25 15.7577 23.25 12.774C23.25 9.7903 22.0647 6.92882 19.955 4.81904C17.8452 2.70925 14.9837 1.52399 12 1.52399C9.01631 1.52399 6.15483 2.70925 4.04505 4.81904C1.93526 6.92882 0.75 9.7903 0.75 12.774C0.75 15.7577 1.93526 18.6192 4.04505 20.7289C6.15483 22.8387 9.01631 24.024 12 24.024Z" fill="currentColor" />
      <path d="M8.99998 1.5C8.99998 1.10218 9.15801 0.720644 9.43932 0.43934C9.72062 0.158035 10.1022 0 10.5 0L13.5 0C13.8978 0 14.2793 0.158035 14.5606 0.43934C14.8419 0.720644 15 1.10218 15 1.5C15 1.89782 14.8419 2.27936 14.5606 2.56066C14.2793 2.84196 13.8978 3 13.5 3H10.5C10.1022 3 9.72062 2.84196 9.43932 2.56066C9.15801 2.27936 8.99998 1.89782 8.99998 1.5ZM10.41 11.16L17.835 6.915L13.59 14.34L6.16498 18.585L10.41 11.16Z" fill="currentColor" />
    </svg>
  );
}

export default Compass;
