import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { FACILITATOR_CURRICULUM_PATH, FACILITATOR_DASHBOARD_PATH, FACILITATOR_INDIGENOUS_WELCOME_LETTERS_PATH,
  FACILITATOR_DISCUSSION_BOARDS_PATH, FACILITATOR_JOURNALS_PATH, FACILITATOR_LIVE_FEED_PATH,
  FACILITATOR_MESSAGES_PATH } from 'lib/routerPaths';
import { GET_CURRENT_USER_NOTIFICATIONS } from 'store/actions';
import { notificationTotals } from 'store/facilitatorDashboard/selectors';
import { GET_ASSIGNMENTS } from 'store/indigenousWelcomeLetters/actions';
import Badge from 'components/shared/Badge/Badge';

// Duplicates app/views/layouts/facilitator/_nav_items.html.erb

function FacilitatorNav() {
  const { id: userId, isFacilitator, isIndigenousPlatformLead, isSuperFacilitator, notifications } = useSelector((state) => state.currentUser || {});
  const { currentFacilitator, loadingSubGroups } = useSelector((state) => state.facilitatorDashboard);
  const { discussionBoards, journals } = useSelector((state) => notificationTotals(state));
  const assignments = useSelector((state) => state.indigenousWelcomeLetters.assignments.allIds);
  const dispatch = useDispatch();
  const unreadMessages = useSelector((state) => state.messages.totalUnreadMessages);

  let dbBubble = discussionBoards;
  let journalBubble = journals;
  if (userId !== currentFacilitator?.id || loadingSubGroups) {
    dbBubble = notifications?.discussionBoardNotifications;
    journalBubble = notifications?.journalNotifications;
  }

  useEffect(() => {
    if (userId === currentFacilitator?.id && !notifications) {
      dispatch(GET_CURRENT_USER_NOTIFICATIONS.request());
    }
  }, [userId, currentFacilitator?.id, notifications, dispatch]);

  useEffect(() => {
    if (isIndigenousPlatformLead) {
      dispatch(GET_ASSIGNMENTS.request({ userId }));
    }
  }, [userId, isIndigenousPlatformLead, dispatch]);

  return (
    <div className="side-nav bg-dark border-end p-2">
      <ul className="nav flex-column">
        <li className="nav-item">
          <Link className="nav-link text-light" to={FACILITATOR_DASHBOARD_PATH}>
            Dashboard
          </Link>
        </li>

        <li className="nav-item">
          <Link className="nav-link text-light" to={FACILITATOR_DISCUSSION_BOARDS_PATH}>
            Discussion Boards
            <Badge title={dbBubble ?? 0} reverse variant="orange" pill className="ms-1" />
          </Link>
        </li>

        <li className="nav-item">
          <Link className="nav-link text-light" to={FACILITATOR_JOURNALS_PATH}>
            Journals
            <Badge title={journalBubble ?? 0} reverse variant="orange" pill className="ms-1" />
          </Link>
        </li>

        {(isFacilitator || isSuperFacilitator) && (
          <li className="nav-item">
            <Link className="nav-link text-light" to={FACILITATOR_MESSAGES_PATH}>
              Messages
              <Badge title={unreadMessages ?? 0} reverse variant="orange" pill className="ms-1" />
            </Link>
          </li>
        )}

        <li className="nav-item">
          <Link className="nav-link text-light" to={FACILITATOR_LIVE_FEED_PATH}>
            Live Feed
          </Link>
        </li>

        <li className="nav-item">
          <a className="nav-link text-light" href="/facilitator/groups">Groups</a>
        </li>

        {(isFacilitator || isSuperFacilitator) && (
          <li className="nav-item">
            <a className="nav-link text-light" href="/facilitator/settings">Settings</a>
          </li>
        )}

        {isIndigenousPlatformLead && (
          <li className="nav-item">
            <Link className="nav-link text-light" to={FACILITATOR_INDIGENOUS_WELCOME_LETTERS_PATH}>
              Indigenous Welcome Letters
              {assignments.length > 0 && <Badge title={assignments.length} variant="green" pill className="ms-1" />}
            </Link>
          </li>
        )}

        <li className="nav-item">
          <Link className="nav-link text-light" to={FACILITATOR_CURRICULUM_PATH}>Courses</Link>
        </li>
      </ul>
    </div>
  );
}

export default FacilitatorNav;
