export const COMPACT_DATE = 'y-MM-dd';
export const SHORT_DATE = 'MMM d, y';
export const FULL_DATE = 'MMMM d, y';
export const COMPACT_TIME = 'HH:mm';
export const SHORT_TIME = 'h:mm aaa';
export const LONG_TIME = 'h:mm:ss aaa';
export const COMPACT_DATE_TIME = `${COMPACT_DATE} ${COMPACT_TIME}`;

export const MONTH_ORDINAL_DAY = 'MMM do';
export const FULL_MONTH_ORDINAL_DAY = 'MMMM do';

export const D_FLATPICKR = 'MM/dd/y';
export const D_GROUP_NAME = 'MMMM dd, y';
export const DT_ISO = "y-MM-dd'T'HH:mm:ssXX";
export const DT_LONG = `EEE, ${SHORT_DATE} ${SHORT_TIME}`;
export const DT_LONG_Z = `${DT_LONG} zzz`;
export const DT_BULK_IMPORT = `EEE, ${SHORT_DATE} 'at' ${SHORT_TIME}`;
export const DT_CLOSE_TIME = `MMMM d 'at' ${SHORT_TIME}`;
export const DT_CLOSE_TIME_DAY = `EEEE, ${DT_CLOSE_TIME}`;
export const DT_IMPORTANT = `EEE, ${MONTH_ORDINAL_DAY}, ${SHORT_TIME} zzz`;
export const DT_LOG_TIME = `${SHORT_DATE} ${LONG_TIME}`;
export const DT_MESSAGE = `MMM d, ${SHORT_TIME}`;
export const DT_MESSAGE_YEAR = `${SHORT_DATE}, ${SHORT_TIME}`;
export const DT_PLATFORM_STATUS = "EEEE, MMMM do 'at' h aaa";
export const DT_PLATFORM_CLOSURE_DETAILS = `EEEE, ${FULL_DATE} 'at' ${SHORT_TIME} zzz`;

// time zones
export const TZ_ATLANTIC = 'America/Halifax';
export const TZ_PACIFIC = 'America/Vancouver';
export const TZ_UTC = 'UTC';
