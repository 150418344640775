import * as yup from 'yup';

// San'yas User::EMAIL_REGEXP
// SN-2344 Allow uppercase letters in email addresses.
// - this is a slight modification to User::EMAIL_REGEXP
// - mostly for convenience on the client side
// - the email is normalized when persisted
const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&'`*+\-/=^_{}|~]+@((\.)?[a-zA-Z0-9-])+\.[a-zA-Z0-9-]{1,63}$/;

export function isValidEmail(message = 'Invalid email') {
  return this.matches(EMAIL_REGEX, { message, excludeEmptyString: true });
}

yup.addMethod(yup.string, 'isValidEmail', isValidEmail);

export default yup;
