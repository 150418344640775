import { REDUX_STATUS, REDUX_SUCCESS } from 'lib/constants';
import {
  CANCEL_COMPOSING_REPLY, CLEAR_SHOW_PAGE, CREATE_REPLY, DELETE_MESSAGE, GET_CONVERSATION, GET_MESSAGES,
  GET_SUB_GROUPS, GET_TOTAL_UNREAD_MESSAGES, MARK_CONVERSATION_AS_READ, RECALL_MESSAGE, GET_MESSAGE,
  RESEND_MESSAGE, RESET_COMPOSE, RESET_MESSAGE, SEND_MESSAGES, SET_PRESELECTED_USER_ID,
  START_COMPOSING_REPLY, UPDATE_CONVERSATION, UPDATE_MESSAGE, UPDATE_SELECTED_RECIPIENTS, UPDATE_TOTAL_UNREAD_MESSAGES,
} from './actions';

const initialState = {
  list: {
    conversations: null,
    conversationIds: null,
    users: null,
    page: null,
    perPage: null,
    totalPages: null,
    userId: null,
  },
  show: {
    conversation: null,
    users: null,
    composingReply: false,
    submittingReply: false,
    submitReplyError: null,
    fetchError: null,
  },
  compose: {
    availableRecipients: null,
    availableSubGroups: null,
    selectedRecipients: null,
    subGroup: null,
    preSelectedUserId: null,
    preSelectedRecipient: null,
    conversationId: null,
    status: REDUX_STATUS.IDLE,
  },
  message: {
    id: undefined,
    data: undefined,
    status: REDUX_STATUS.IDLE,
    success: undefined,
    error: undefined,
  },
  totalUnreadMessages: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_MESSAGES.REQUEST: {
      if (state.list.userId === action.payload.userId) return state;

      return {
        ...state,
        list: {
          ...initialState.list,
        },
      };
    }
    case GET_MESSAGES.SUCCESS: {
      if (action.meta.cached) return state;

      let newConversations;
      let newConversationIds;
      let newUsers;

      if (state.list.userId === action.payload.meta.userId) {
        const existingConversationIds = state.list.conversationIds || [];

        newConversationIds = Array.from(new Set([
          ...existingConversationIds,
          ...action.payload.data.conversationIds,
        ]));
        newConversations = {
          ...state.list.conversations,
          ...action.payload.data.conversations,
        };
        newUsers = {
          ...state.list.users,
          ...action.payload.data.users,
        };
      } else {
        newConversations = action.payload.data.conversations;
        newConversationIds = action.payload.data.conversationIds;
        newUsers = action.payload.data.users;
      }

      return {
        ...state,
        list: {
          ...state.list,
          ...action.payload.meta,
          conversations: newConversations,
          conversationIds: newConversationIds,
          users: newUsers,
        },
      };
    }

    case GET_CONVERSATION.SUCCESS:
      return {
        ...state,
        show: {
          ...state.show,
          conversation: action.payload.conversation,
          users: action.payload.users,
        },
      };
    case GET_CONVERSATION.ERROR:
      return {
        ...state,
        show: {
          ...state.show,
          fetchError: action.payload,
        },
      };

    case CLEAR_SHOW_PAGE.SYNC:
      return {
        ...state,
        show: {
          ...initialState.show,
        },
      };

    case UPDATE_CONVERSATION.REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          conversations: {
            ...state.list.conversations,
            [action.payload.id]: {
              ...state.list.conversations[action.payload.id],
              subject: action.payload.subject ?? state.show.conversation.subject,
              isFlagged: action.payload.isFlagged ?? state.show.conversation.isFlagged,
            },
          },
        },
        show: {
          ...state.show,
          conversation: {
            ...state.show.conversation,
            subject: action.payload.subject ?? state.show.conversation.subject,
            isFlagged: action.payload.isFlagged ?? state.show.conversation.isFlagged,
          },
        },
      };

    case UPDATE_CONVERSATION.SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          conversations: {
            ...state.list.conversations,
            [action.payload.id]: {
              ...state.list.conversations[action.payload.id],
              ...action.payload,
            },
          },
        },
        show: {
          ...state.show,
          conversation: {
            ...state.show.conversation,
            ...action.payload,
          },
        },
      };

    case MARK_CONVERSATION_AS_READ.SUCCESS:
      return {
        ...state,
        show: {
          ...state.show,
          conversation: {
            ...state.show.conversation,
            messages: [
              ...state.show.conversation.messages.map((m) => ({ ...m, readAt: new Date() })),
            ],
          },
        },
        list: {
          ...state.list,
          conversations: {
            ...state.list.conversations,
            [action.payload.conversationId]: {
              ...state.list.conversations[action.payload.conversationId],
              messages: [
                ...state.list.conversations[action.payload.conversationId].messages.map((m) => ({ ...m, readAt: new Date() })),
              ],
            },
          },
        },
      };
    case START_COMPOSING_REPLY.SYNC:
      return {
        ...state,
        show: {
          ...state.show,
          composingReply: true,
          submitReplyError: null,
        },
      };
    case CANCEL_COMPOSING_REPLY.SYNC:
      return {
        ...state,
        show: {
          ...state.show,
          composingReply: false,
          submittingReply: false,
          submitReplyError: null,
        },
      };
    case CREATE_REPLY.REQUEST:
      return {
        ...state,
        show: {
          ...state.show,
          composingReply: false,
          submittingReply: true,
        },
      };
    case CREATE_REPLY.SUCCESS:
      return {
        ...state,
        show: {
          ...state.show,
          conversation: {
            ...state.show.conversation,
            messages: [...state.show.conversation.messages, action.payload],
          },
          submittingReply: false,
          composingReply: false,
          submitReplyError: null,
        },
      };
    case CREATE_REPLY.ERROR:
      return {
        ...state,
        show: {
          ...state.show,
          submittingReply: false,
          composingReply: true,
          submitReplyError: 'Your message could not be sent.',
        },
      };
    case GET_SUB_GROUPS.SUCCESS: {
      if (action.meta.cached) return state;

      const preSelectedRecipient = (
        state.compose.preSelectedUserId
          && action.payload.participants.find((p) => p.userId === state.compose.preSelectedUserId)
      );

      return {
        ...state,
        compose: {
          ...state.compose,
          availableSubGroups: action.payload.subGroups,
          availableRecipients: action.payload.participants,
          preSelectedRecipient,
        },
      };
    }
    case UPDATE_SELECTED_RECIPIENTS.SYNC: {
      const somePodsSelected = action.payload.length > 0;
      const onlyPodsSelected = somePodsSelected && !!action.payload[0].subGroupId;
      const selectedRecipients = onlyPodsSelected
        ? { subGroupIds: action.payload.map((v) => v.subGroupId) }
        : { groupMembershipIds: action.payload.map((v) => v.groupMembership.id) };

      return {
        ...state,
        compose: {
          ...state.compose,
          selectedRecipients,
        },
      };
    }
    case SET_PRESELECTED_USER_ID.SYNC:
      return {
        ...state,
        compose: {
          ...state.compose,
          preSelectedUserId: action.payload.userId,
        },
      };

    case SEND_MESSAGES.REQUEST:
      return {
        ...state,
        message: {
          ...initialState.message,
        },
      };
    case SEND_MESSAGES.SUCCESS:
      return {
        ...state,
        compose: {
          ...state.compose,
          conversationId: action.payload.conversationIds[0],
          status: REDUX_STATUS.SUCCESS,
        },
        list: {
          ...state.list,
          conversations: {
            ...state.list.conversations,
            ...action.payload.conversations,
          },
          conversationIds: [...action.payload.conversationIds, ...state.list.conversationIds],
        },
      };

    case RESET_COMPOSE.SYNC:
      return {
        ...state,
        compose: {
          ...initialState.compose,
        },
      };

    case GET_TOTAL_UNREAD_MESSAGES.SUCCESS:
      return {
        ...state,
        totalUnreadMessages: action.payload,
      };
    case UPDATE_TOTAL_UNREAD_MESSAGES.SYNC: {
      const totalUnreadMessages = Object.values(state.list.conversations)
        .reduce((acc, convo) => acc + (convo.messages?.some((m) => !m.readAt && m.toUserId === action.payload.userId) ? 1 : 0), 0);

      return {
        ...state,
        totalUnreadMessages,
      };
    }

    case GET_MESSAGE.SUCCESS:
      return {
        ...state,
        message: {
          ...state.message,
          id: action.payload.id,
          data: action.payload,
        },
        show: {
          ...state.show,
          conversation: {
            ...state.show.conversation,
            messages: state.show.conversation.messages.map((m) => (m.id === action.payload.id ? action.payload : m)),
          },
        },
      };
    case GET_MESSAGE.ERROR:
      return {
        ...state,
        message: {
          ...state.message,
          status: REDUX_STATUS.ERROR,
          error: action.payload,
        },
      };

    case RECALL_MESSAGE.REQUEST:
      return {
        ...state,
        message: {
          ...initialState.message,
          id: action.payload,
          status: REDUX_STATUS.PENDING,
        },
      };
    case RECALL_MESSAGE.SUCCESS:
      return {
        ...state,
        message: {
          ...state.message,
          data: action.payload,
          status: REDUX_STATUS.SUCCESS,
        },
        show: {
          ...state.show,
          conversation: {
            ...state.show.conversation,
            messages: state.show.conversation.messages.map((m) => (m.id === action.payload.id ? action.payload : m)),
          },
        },
      };
    case RECALL_MESSAGE.ERROR:
      return {
        ...state,
        message: {
          ...state.message,
          status: REDUX_STATUS.ERROR,
          error: action.payload,
        },
      };

    case RESEND_MESSAGE.REQUEST:
      return {
        ...state,
        message: {
          ...initialState.message,
          id: action.payload.id,
          data: action.payload,
          status: REDUX_STATUS.PENDING,
        },
      };
    case RESEND_MESSAGE.SUCCESS:
      return {
        ...state,
        message: {
          ...state.message,
          data: action.payload,
          status: REDUX_STATUS.SUCCESS,
        },
        show: {
          ...state.show,
          conversation: {
            ...state.show.conversation,
            messages: state.show.conversation.messages.map((m) => (m.id === action.payload.id ? action.payload : m)),
          },
        },
      };
    case RESEND_MESSAGE.ERROR:
      return {
        ...state,
        message: {
          ...state.message,
          status: REDUX_STATUS.ERROR,
          error: action.payload,
        },
      };

    case UPDATE_MESSAGE.REQUEST:
      return {
        ...state,
        message: {
          ...initialState.message,
          id: action.payload.id,
          data: action.payload,
          status: REDUX_STATUS.PENDING,
        },
      };
    case UPDATE_MESSAGE.SUCCESS:
      return {
        ...state,
        message: {
          ...state.message,
          data: action.payload,
          status: REDUX_STATUS.SUCCESS,
        },
        show: {
          ...state.show,
          conversation: {
            ...state.show.conversation,
            messages: state.show.conversation.messages.map((m) => (m.id === action.payload.id ? action.payload : m)),
          },
        },
      };
    case UPDATE_MESSAGE.ERROR:
      return {
        ...state,
        message: {
          ...state.message,
          status: REDUX_STATUS.ERROR,
          error: action.payload,
        },
      };

    case DELETE_MESSAGE.REQUEST:
      return {
        ...state,
        message: {
          ...initialState.message,
          id: action.payload,
          status: REDUX_STATUS.PENDING,
        },
      };
    case DELETE_MESSAGE.SUCCESS: {
      const { conversation } = state.show;
      let { conversations: newConversations, conversationIds: newConversationIds } = state.list;
      if (conversation.messages.length === 1) { // If this is the last message in the conversation
        newConversationIds = state.list.conversationIds.filter((id) => id !== conversation.id);
        newConversations = { ...state.list.conversations };
        delete newConversations[conversation.id];
      }

      return {
        ...state,
        message: {
          ...state.message,
          status: REDUX_STATUS.SUCCESS,
          success: REDUX_SUCCESS.DELETED,
        },
        show: {
          ...state.show,
          conversation: {
            ...state.show.conversation,
            messages: state.show.conversation.messages.filter((m) => m.id !== action.payload),
          },
        },
        list: {
          ...state.list,
          conversationIds: newConversationIds,
          conversations: newConversations,
        },
      };
    }
    case DELETE_MESSAGE.ERROR:
      return {
        ...state,
        message: {
          ...state.message,
          status: REDUX_STATUS.ERROR,
          error: action.payload,
        },
      };

    case RESET_MESSAGE.SYNC:
      return {
        ...state,
        message: {
          ...initialState.message,
        },
      };

    default:
      return state;
  }
};
