import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { format, parseISO } from 'date-fns';
import { SHORT_DATE } from 'lib/dateFormats';
import { DiscussionBoardNotificationPropType } from 'lib/propTypes';
import { buildRoutePath } from 'lib/routerHelpers';
import { FACILITATOR_DISCUSSION_BOARD_COMMENTS_PATH } from 'lib/routerPaths';
import Badge from 'components/shared/Badge/Badge';
import PostFlag from 'components/shared/Comments/PostFlag';
import QuicknotesPopover from '../QuicknotesPopover';
import Prioritized from '../Prioritized';

function DiscussionBoardRow({ discussionBoard }) {
  const {
    internalName,
    groupName,
    subGroupName,
    groupBeginSessionAt,
    groupCloseSessionAt,
    groupExtendedUntil,
    highPriorityTotal,
    mediumPriorityTotal,
    lowPriorityTotal,
    discussionBoardId: discussionTaskId,
    groupId: id,
    subGroupId,
    flags,
    note,
    assignedFacilitatorId,
  } = discussionBoard;

  const currentUser = useSelector((state) => state.currentUser);
  const isAssignedFacilitationPoint = currentUser?.id === assignedFacilitatorId;
  const boardPath = buildRoutePath(FACILITATOR_DISCUSSION_BOARD_COMMENTS_PATH, { id, subGroupId, taskId: discussionTaskId });

  return (
    <tr key={discussionTaskId} className="text-small">
      <td className="pe-0">
        {flags?.map((flag) => <PostFlag key={discussionTaskId + flag} flag={flag} />)}
      </td>
      <td className="position-relative text-center">
        <Link to={boardPath} className="stretched-link">
          <Badge
            variant={internalName.toLowerCase()}
            title={internalName}
          />
        </Link>
      </td>
      <td>
        <Link to={boardPath} className="btn-plain fw-semibold">
          {groupName}
        </Link>
      </td>
      <td>{subGroupName}</td>
      <td className="text-nowrap">
        {format(parseISO(groupBeginSessionAt), SHORT_DATE)}
      </td>
      <td className="text-nowrap">
        {format(parseISO(groupCloseSessionAt), SHORT_DATE)}
      </td>
      <td className="text-nowrap">
        {!!groupExtendedUntil && format(parseISO(groupExtendedUntil), SHORT_DATE)}
      </td>
      <td className="text-nowrap">
        <Badge
          variant="red"
          title={highPriorityTotal}
          className="me-1"
        />
        <Badge
          variant="orange"
          title={mediumPriorityTotal}
          className="me-1"
        />
        <Badge
          variant="green"
          title={lowPriorityTotal}
          className="me-1"
        />
      </td>
      <td className="text-nowrap">
        {isAssignedFacilitationPoint && (
          <>
            <Prioritized
              note={note || { subGroupId, taskId: discussionTaskId }}
              assigned={isAssignedFacilitationPoint}
              variant={null}
              className="journal-row-buttons border-0 me-4"
              iconSize="xl"
            />
            <QuicknotesPopover note={note} />
          </>
        )}
      </td>
      <td className="position-relative">
        <Link to={boardPath} className="btn-plain stretched-link">
          View
        </Link>
      </td>
    </tr>
  );
}

DiscussionBoardRow.propTypes = {
  discussionBoard: DiscussionBoardNotificationPropType.isRequired,
};

export default DiscussionBoardRow;
