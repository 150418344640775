import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { MessagePropType } from 'lib/propTypes';
import { RESEND_MESSAGE, UPDATE_MESSAGE } from 'store/messages/actions';
import { Button, Spinner } from 'react-bootstrap';
import TextareaField from 'components/shared/FormFields/TextareaField';

const FormSchema = yup.object().shape({
  body: yup.string().required('Required'),
});

function EditMessage({ message, onCancel }) {
  const dispatch = useDispatch();

  const handleUpdateMessage = (values, actions) => {
    const ACTION = message.state === 'delivered' ? UPDATE_MESSAGE : RESEND_MESSAGE;
    dispatch(ACTION.request(values, { actions }));
  };

  return (
    <Formik initialValues={{ ...message }} validationSchema={FormSchema} onSubmit={handleUpdateMessage}>
      {({ isSubmitting }) => (
        <Form className="mb-2">
          <TextareaField
            name="body"
            label={`Editing message from ${message.fromUser.firstName} ${message.fromUser.lastName}`}
            rows="8"
            placeholder="Type your message here"
            required
          />

          <div className="d-flex justify-content-end">
            <Button type="submit" variant="primary" className="order-2" disabled={isSubmitting}>
              {message.state === 'delivered' ? 'Update' : 'Send'}
              {isSubmitting && <Spinner size="sm" className="ms-1" animation="border" role="status" />}
            </Button>
            <Button variant="plain" className="order-1" onClick={onCancel}>
              Cancel
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

EditMessage.defaultProps = {
  onCancel: () => {},
};

EditMessage.propTypes = {
  message: MessagePropType.isRequired,
  onCancel: PropTypes.func,
};

export default EditMessage;
