import _union from 'lodash/union';
import { CREATE_COVERAGE_REPORT, FETCH_ALL_COVERAGE_REPORTS, GET_COVERAGE_FORM_DATA, CREATE_FACILITATOR_OVERVIEW_REPORT, FETCH_ALL_FACILITATOR_OVERVIEW_REPORTS, GET_FACILITATOR_OVERVIEW_FORM_DATA } from './actions';

const initialState = {
  formLoading: true,
  loading: true,
  reports: {
    allIds: [],
    byId: {},
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_COVERAGE_FORM_DATA.REQUEST:
      return {
        ...state,
        submitting: true,
        formLoading: true,
      };
    case GET_COVERAGE_FORM_DATA.SUCCESS:
      return {
        ...state,
        formData: action.payload,
        submitting: false,
        formLoading: false,
      };
    case GET_COVERAGE_FORM_DATA.FAILURE:
      return {
        ...state,
        submitting: false,
        formLoading: false,
      };
    case GET_FACILITATOR_OVERVIEW_FORM_DATA.REQUEST:
      return {
        ...state,
        submitting: true,
        formLoading: true,
      };
    case GET_FACILITATOR_OVERVIEW_FORM_DATA.SUCCESS:
      return {
        ...state,
        formData: action.payload,
        submitting: false,
        formLoading: false,
      };
    case GET_FACILITATOR_OVERVIEW_FORM_DATA.ERROR:
      return {
        ...state,
        submitting: false,
        formLoading: false,
      };
    case CREATE_COVERAGE_REPORT.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_COVERAGE_REPORT.SUCCESS:
      return {
        ...state,
        loading: false,
        reports: {
          allIds: _union(action.payload.reportIds, state.reports.allIds),
          byId: { ...state.reports.byId, ...action.payload.reports },
        },
      };
    case CREATE_COVERAGE_REPORT.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CREATE_FACILITATOR_OVERVIEW_REPORT.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_FACILITATOR_OVERVIEW_REPORT.SUCCESS:
      return {
        ...state,
        loading: false,
        reports: {
          allIds: _union(action.payload.reportIds, state.reports.allIds),
          byId: { ...state.reports.byId, ...action.payload.reports },
        },
      };
    case CREATE_FACILITATOR_OVERVIEW_REPORT.ERROR:
      return {
        ...state,
        loading: false,
      };
    case FETCH_ALL_COVERAGE_REPORTS.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ALL_COVERAGE_REPORTS.SUCCESS:
      return {
        ...state,
        loading: false,
        reports: {
          allIds: action.payload.reportIds,
          byId: action.payload.reports,
        },
      };
    case FETCH_ALL_COVERAGE_REPORTS.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case FETCH_ALL_FACILITATOR_OVERVIEW_REPORTS.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ALL_FACILITATOR_OVERVIEW_REPORTS.SUCCESS:
      return {
        ...state,
        loading: false,
        reports: {
          allIds: action.payload.reportIds,
          byId: action.payload.reports,
        },
      };
    case FETCH_ALL_FACILITATOR_OVERVIEW_REPORTS.FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
