import React from 'react';
import { Link } from 'react-router-dom';

function SuperFacilitatorNav() {
  return (
    <div className="side-nav bg-dark border-end p-2">
      <ul className="nav flex-column">
        <li className="nav-item">
          <Link className="nav-link text-light" to="/lead_facilitator">Dashboard</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link text-light" to="/lead_facilitator/reports">Reports</Link>
        </li>

        {/* Pending implementation
        {isSuperFacilitator && (
          <li className="nav-item">
            <Link className="nav-link text-light" to={FACILITATOR_MESSAGES_PATH}>
              Messages
              <Badge title={unreadMessages ?? 0} reverse variant="orange" pill className="ms-1" />
            </Link>
          </li>
        )} */}
      </ul>
    </div>
  );
}

export default SuperFacilitatorNav;
