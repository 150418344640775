import { asyncAction, syncAction } from 'lib/actionCreatorCreators';

export const GET_GROUP = asyncAction('GET_GROUP');
export const GET_GROUP_PARTICIPATIONS = asyncAction('GET_GROUP_PARTICIPATIONS');
export const RESET_GROUP_PARTICIPATIONS = syncAction('RESET_GROUP_PARTICIPATIONS');
export const ASSIGN_COURSE = asyncAction('ASSIGN_COURSE');
export const EXTEND_GROUP = asyncAction('EXTEND_GROUP');
export const UPDATE_GROUP_SETTINGS = asyncAction('UPDATE_GROUP_SETTINGS');
export const EDIT_GROUP = syncAction('EDIT_GROUP');
export const GET_LIVE_FEED = asyncAction('GET_LIVE_FEED');
export const GET_JOURNALS = asyncAction('GET_JOURNALS');
export const LOAD_RESERVATIONS = asyncAction('LOAD_RESERVATIONS');
export const UPDATE_RESERVATIONS = asyncAction('UPDATE_RESERVATIONS');
export const CREATE_PARTICIPANT = asyncAction('CREATE_PARTICIPANT');
export const UPDATE_REVIEW = asyncAction('UPDATE_REVIEW');
export const CHANGE_GROUP_STATE = asyncAction('CHANGE_GROUP_STATE');
export const REMOVE_PARTICIPANTS = asyncAction('REMOVE_PARTICIPANTS');
export const RESET_GROUP_STATUS = syncAction('RESET_GROUP_STATUS');
export const UPDATE_GROUP_SHOW_DATA = syncAction('UPDATE_GROUP_SHOW_DATA');
export const UPDATE_GROUP_PARTICIPATIONS = syncAction('UPDATE_GROUP_PARTICIPATIONS');
export const REMOVE_GROUP_PARTICIPATION = syncAction('REMOVE_GROUP_PARTICIPATION');
export const CHANGE_PARTICIPANTS_GROUP = asyncAction('CHANGE_PARTICIPANTS_GROUP');
