import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ConversationPropType } from 'lib/propTypes';
import { FACILITATOR_GROUP_SHOW_PATH } from 'lib/routerPaths';
import MultilineText from 'components/shared/MultilineText';
import { buildRoutePath } from 'lib/routerHelpers';
import { Alert } from 'react-bootstrap';
import { formatDate } from '../utils';

function GroupConversation({ conversation }) {
  const currentUser = useSelector((state) => state.currentUser);
  const firstMessage = conversation.messages[0];
  const recipients = conversation
    .messages
    .map((m) => `${m.toUser.firstName} ${m.toUser.lastName}`)
    .sort((a, b) => a.localeCompare(b))
    .join(', ');
  const { groupName, groupId } = firstMessage.toUser;

  return (
    <>
      <Alert variant="light" className="mb-3 py-2 text-small">
        This message was sent to your Pod in
        {' '}
        <Link to={buildRoutePath(FACILITATOR_GROUP_SHOW_PATH, { id: groupId })}>{groupName}</Link>
        .
        <p className="mt-1 mb-0">{recipients}</p>
      </Alert>

      <div className={`col-11 ${firstMessage.fromUser.id === currentUser.id ? 'offset-1 rounded-start-4 messages--current-user' : 'rounded-end-4 bg-light'} mb-2 border rounded-bottom-4 py-2 px-3`}>
        <p className="fw-semibold">
          {firstMessage.fromUser.id === currentUser.id && (
          <span>{`${firstMessage.fromUser.firstName} ${firstMessage.fromUser.lastName}`}</span>
          )}
          <small className="ps-2">{formatDate(firstMessage.createdAt)}</small>
        </p>
        <MultilineText text={firstMessage.body} />
      </div>
    </>
  );
}

GroupConversation.propTypes = {
  conversation: ConversationPropType.isRequired,
};

export default GroupConversation;
