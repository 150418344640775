import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { MessagePropType } from 'lib/propTypes';
import { REDUX_STATUS } from 'lib/constants';
import EditMessage from './EditMessage';
import Message from './Message';

function ConversationMessage({ message, userId, onEditMessage }) {
  const { status } = useSelector((state) => state.messages.message);
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    if ([REDUX_STATUS.SUCCESS, REDUX_STATUS.ERROR].includes(status)) {
      setEditing(false);
      onEditMessage(false);
    }
  }, [status, onEditMessage]);

  const handleEditMessage = () => {
    setEditing(true);
    onEditMessage(true);
  };

  const handleCancelEditing = () => {
    setEditing(false);
    onEditMessage(false);
  };

  return (
    <>
      {editing && (
        <EditMessage message={message} onCancel={handleCancelEditing} />
      )}

      {!editing && (
        <Message message={message} userId={userId} onEdit={handleEditMessage} />
      )}
    </>
  );
}

ConversationMessage.defaultProps = {
  userId: null,
};

ConversationMessage.propTypes = {
  message: MessagePropType.isRequired,
  userId: PropTypes.number,
  onEditMessage: PropTypes.func.isRequired,
};

export default ConversationMessage;
