import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GET_MESSAGES } from 'store/messages/actions';
import { Button } from 'react-bootstrap';
import { UserPropType } from 'lib/propTypes';
import ErrorBoundary from 'components/shared/ErrorBoundary';
import MessagesListItem from './MessagesListItem';

function MessagesList({ user }) {
  const currentUser = useSelector((state) => state.currentUser);
  const { conversationIds, page, totalPages, users } = useSelector((state) => state.messages.list);
  const activeConversation = useSelector((state) => state.messages.show.conversation);
  const [showPage, setShowPage] = useState(1);
  const dispatch = useDispatch();

  const handlePageClick = useCallback(() => {
    setShowPage((prev) => prev + 1);
  }, []);

  useEffect(() => {
    if (user?.id || currentUser?.id) {
      dispatch(GET_MESSAGES.request({ userId: user?.id || currentUser?.id, page: showPage }));
    }
  }, [user?.id, currentUser?.id, showPage, dispatch]);

  if (conversationIds?.length === 0) return null;

  return (
    <ErrorBoundary>
      <Fragment key={user?.id || currentUser?.id}>
        <ul className="list-unstyled">
          {conversationIds?.map((cid) => (
            <MessagesListItem
              key={cid}
              conversationId={cid}
              users={users}
              active={activeConversation?.id === cid}
            />
          ))}
        </ul>

        {(conversationIds?.length > 0 && totalPages > page) && (
        <Button
          variant="primary"
          className="btn-plain w-100 text-start text-nowrap border-top py-2 px-4"
          onClick={handlePageClick}
        >
          Load more
        </Button>
        )}
      </Fragment>
    </ErrorBoundary>
  );
}

MessagesList.defaultProps = {
  user: null,
};

MessagesList.propTypes = {
  user: UserPropType,
};

export default MessagesList;
