import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { buildRoutePath } from 'lib/routerHelpers';
import { REGISTRAR_USERS_GROUP_MEMBERSHIP_PATH } from 'lib/routerPaths';
import SanyasIcon from 'components/shared/SanyasIcon';

function ProgressTableRow({ participant }) {
  const { sectionIds, sections } = useSelector((state) => state.groupReports.progress.data);

  return (
    <tr>
      <td className="py-1 px-2 text-nowrap">
        <Link
          to={buildRoutePath(REGISTRAR_USERS_GROUP_MEMBERSHIP_PATH, { id: participant.userId, groupMembershipId: participant.id })}
          className="btn-plain fw-semibold text-small"
        >
          {participant.fullName}
        </Link>
      </td>

      {sectionIds.map((sectionId, index) => {
        const bg = index % 2 ? null : 'var(--bs-sn-clr-light-green)';
        return (
          <td key={sectionId} className="p-1 text-nowrap" style={{ backgroundColor: bg }}>
            {sections[sectionId]?.taskIds?.map((taskId) => (
              <Fragment key={taskId}>
                {participant.tasks[taskId]?.completedAt ? (
                  <SanyasIcon name="checkCircle" className="text-success" />
                ) : (
                  <SanyasIcon
                    name="circle"
                    className="text-accent-deep-grey"
                    style={{ paddingBottom: 1, paddingLeft: 2, width: 16 }}
                  />
                )}
              </Fragment>
            ))}
          </td>
        );
      })}
    </tr>
  );
}

ProgressTableRow.propTypes = {
  participant: PropTypes.shape({
    id: PropTypes.number.isRequired,
    userId: PropTypes.number.isRequired,
    fullName: PropTypes.string.isRequired,
    tasks: PropTypes.objectOf(PropTypes.shape({
      completedAt: PropTypes.string,
    })).isRequired,
  }).isRequired,
};

export default ProgressTableRow;
