import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import _throttle from 'lodash/throttle';
import { RefPropType, UserPropType } from 'lib/propTypes';
import { FACILITATOR_PARTICIPANTS_CONVERSATION_PATH } from 'lib/routerPaths';
import MessagesList from 'components/shared/Messages/MessagesList';
import SanyasIcon from 'components/shared/SanyasIcon';
import MessagesShowPage from '../Messages/MessagesShowPage';

function MessagesTabPage({ user, headerRef }) {
  const sidebarRef = useRef(null);
  const envBannerHeight = document.getElementById('env-banner')?.offsetHeight || 0;
  const navbarHeight = document.querySelector('nav.navbar')?.offsetHeight || 0;
  const maxHeight = window.innerHeight;
  const initBaseHeight = maxHeight - envBannerHeight - navbarHeight - (headerRef.current?.offsetHeight || 0);
  const [baseHeight, setBaseHeight] = useState(initBaseHeight);

  const throttledScroll = useMemo(() => _throttle(() => {
    if (!sidebarRef.current) return;

    const newHeight = `${Math.min(maxHeight, baseHeight + window.scrollY)}px`;
    if (sidebarRef.current.style.height !== newHeight) {
      sidebarRef.current.style.height = newHeight;
    }
  }, 150), [baseHeight, maxHeight]);

  const handleScroll = useCallback(throttledScroll, [throttledScroll]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  useEffect(() => {
    setBaseHeight(initBaseHeight);
  }, [initBaseHeight]);

  return (
    <div className="row mx-0">
      <div className="col-4 border-end p-0">
        <div
          ref={sidebarRef}
          className="overflow-auto position-sticky"
          style={{ height: `${baseHeight}px`, top: 0, transition: 'height 0.15s ease-in' }}
        >
          <MessagesList user={user} />
        </div>
      </div>
      <div className="col-8 bg-light">
        <Switch>
          <Route path={FACILITATOR_PARTICIPANTS_CONVERSATION_PATH} exact>
            <MessagesShowPage user={user} />
          </Route>
          <Route path="*">
            <div className="position-relative h-100">
              <div className="position-absolute top-50 start-50 translate-middle">
                <div className="bg-white p-5 rounded-circle shadow">
                  <SanyasIcon name="inbox" size="4x" className="text-primary" />
                </div>
              </div>
            </div>
          </Route>
        </Switch>
      </div>
    </div>
  );
}

MessagesTabPage.defaultProps = {
  user: null,
  headerRef: {
    current: null,
  },
};

MessagesTabPage.propTypes = {
  user: UserPropType,
  headerRef: RefPropType,
};

export default MessagesTabPage;
