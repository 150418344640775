import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useScrollToLocation from 'lib/hooks/useScrollToLocation';
import { LOAD_COMMENTS } from 'store/facilitatorDashboard/actions';
import ErrorMessage from 'components/shared/ErrorMessage';
import FacilitatorTemplate from 'components/templates/FacilitatorTemplate';
import CommentsHeader from 'components/facilitator/DiscussionBoards/CommentsHeader';
import Spinner from 'components/shared/Spinner';
import Comment from 'components/shared/Comments/Comment';
import Conversation from 'components/shared/Comments/Conversation';
import NotesPanel from 'components/facilitator/NotesPanel';

function CommentsShowPage() {
  const params = useParams();
  const { error, loadingComments, commentIds } = useSelector((state) => state.facilitatorDashboard);
  const dispatch = useDispatch();

  const [showNotesPanel, setShowNotesPanel] = useState(false);
  const handleClose = () => setShowNotesPanel(false);
  const toggleShow = () => setShowNotesPanel((prev) => !prev);

  useEffect(() => {
    dispatch(LOAD_COMMENTS.request(params));
  }, [dispatch, params]);

  useScrollToLocation();

  return (
    <FacilitatorTemplate className="bg-light">
      <CommentsHeader className="mb-4" toggleNotes={toggleShow} />
      <ErrorMessage error={{ message: error }} />

      <NotesPanel context="discussionTask" show={showNotesPanel} onHide={handleClose} />

      {loadingComments && (
        <div className="h-50">
          <Spinner size="3x" className="ms-1" animation="border" role="status" />
        </div>
      )}

      {(!loadingComments && commentIds) && (
        <div className="col-8 mx-auto" style={{ maxWidth: '935px' }}>
          {commentIds?.map((commentId) => (
            <Conversation key={commentId} commentId={commentId} />
          ))}

          <Comment taskId={params.taskId} subGroupId={params.subGroupId} />
        </div>
      )}
    </FacilitatorTemplate>
  );
}

export default CommentsShowPage;
