import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link, useParams, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { format, parseISO, subDays } from 'date-fns';
import { SHORT_DATE } from 'lib/dateFormats';
import { buildRoutePath } from 'lib/routerHelpers';
import { FACILITATOR_GROUP_SHOW_PATH, FACILITATOR_JOURNAL_ANSWERS_PATH, FACILITATOR_JOURNAL_PREVIEW_COMMENTS_PATH, FACILITATOR_JOURNALS_PATH } from 'lib/routerPaths';
import { parseSubGroupName } from 'lib/utils';
import { fetchSubGroupData } from 'store/facilitatorDashboard/selectors';
import { GET_SUB_GROUP_DATA } from 'store/facilitatorDashboard/actions';
import { Button } from 'react-bootstrap';
import SanyasIcon from 'components/shared/SanyasIcon';
import Skeleton from 'components/shared/Skeleton';
import Badge from 'components/shared/Badge/Badge';
import SimpleToolTip from 'components/shared/SimpleToolTip/SimpleToolTip';
import Prioritized from '../Prioritized';

function JournalHeader({ className, toggleNotes }) {
  const { id, subGroupId, taskId } = useParams();
  const { path } = useRouteMatch();
  const currentUser = useSelector((state) => state.currentUser);
  const { assignedFacilitatorId, journalPreviewsById } = useSelector((state) => state.facilitatorDashboard);
  const subGroupData = useSelector((state) => fetchSubGroupData(state, subGroupId));
  const dispatch = useDispatch();
  const subGroupName = parseSubGroupName(subGroupData?.subGroupName);
  const journal = subGroupData?.journalNotifications[taskId];
  const isAssignedFacilitationPoint = currentUser?.id === assignedFacilitatorId && subGroupData?.facilitatedTaskIds?.includes(Number(taskId));

  const recentPosts = useMemo(
    () => journalPreviewsById && Object.values(journalPreviewsById)?.filter((answer) => new Date(answer.createdAt) > subDays(new Date(), 2)),
    [journalPreviewsById],
  );

  useEffect(() => {
    if (!subGroupData) {
      dispatch(GET_SUB_GROUP_DATA.request({ subGroupId }));
    }
  }, [subGroupId, subGroupData, dispatch]);

  return (
    <div className={`flush sticky-top d-flex align-items-center bg-white border-bottom ${className || ''}`}>
      <div className="d-flex align-items-center align-self-stretch border-end px-4">
        <Link
          className="text-accent-deep-grey"
          to={path === FACILITATOR_JOURNAL_ANSWERS_PATH
            ? buildRoutePath(FACILITATOR_JOURNAL_PREVIEW_COMMENTS_PATH, { id, subGroupId, taskId })
            : FACILITATOR_JOURNALS_PATH}
        >
          <SanyasIcon name="chevronLeft" size="2x" />
        </Link>
      </div>

      <div className="d-flex align-items-center w-100 py-3 px-4">

        {!subGroupData && (
          <div className="w-100">
            <Skeleton classes="title width-quarter d-block mb-0" />
            <Skeleton classes="text width-quarter" />
          </div>
        )}

        {subGroupData && (
          <>
            <div className="d-flex flex-column">
              <div className="d-flex flex-wrap">
                <Link
                  to={buildRoutePath(FACILITATOR_GROUP_SHOW_PATH, { id })}
                  className="me-2 btn-plain"
                >
                  <strong>{subGroupData.groupName}</strong>
                </Link>
                <div>
                  <span className="text-small">{subGroupName.pod}</span>
                  <span className="px-2 text-small">&middot;</span>
                  <span className="text-small">
                    {format(parseISO(subGroupData.groupBeginSessionAt), SHORT_DATE)}
                    <span className="px-1">-</span>
                    {format(parseISO(subGroupData.groupCloseSessionAt), SHORT_DATE)}
                  </span>
                </div>
              </div>
              <div>
                <Badge title={journal.internalName} variant={journal.internalName.toLowerCase()} className="me-2" />
                <span>{journal.name}</span>
              </div>
            </div>

            <div className="ms-4 border-start ps-4 text-small">
              <strong>{`${journal?.responseCount}/${subGroupData.participantCount}`}</strong>
              <br />
              responded
            </div>

            <div className="ms-4 border-start ps-4 text-small">
              <SimpleToolTip placement="bottom" text="New posts or replies in the last 2 days">
                <strong>{recentPosts?.length || 0}</strong>
                <br />
                {`recent post${recentPosts?.length !== 1 ? 's' : ''}`}
              </SimpleToolTip>
            </div>

            {isAssignedFacilitationPoint && (
              <div className="ms-4 border-start ps-4 text-small">
                <strong>{journal?.notificationTotal}</strong>
                <br />
                {`unread post${journal?.notificationTotal !== 1 ? 's' : ''}`}
              </div>
            )}

            {subGroupName.facilitators && (
              <div className="ms-4 border-start ps-4 text-small">
                <strong>Facilitators</strong>
                <br />
                {subGroupName.facilitators}
              </div>
            )}

            {isAssignedFacilitationPoint && (
              <>
                {!!toggleNotes && (
                  <div className="ms-auto ps-4 d-flex flex-wrap gap-1">
                    <Button variant="outline-secondary" size="sm" className="text-nowrap" onClick={toggleNotes}>
                      Edit notes
                    </Button>
                  </div>
                )}

                <Prioritized
                  note={journal.note || { subGroupId, taskId }}
                  assigned={isAssignedFacilitationPoint}
                  text={['Prioritize', 'Prioritized']}
                  variant="plain"
                  className="text-nowrap text-decoration-none"
                  iconSize="lg"
                />
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}

JournalHeader.defaultProps = {
  className: null,
  toggleNotes: null,
};

JournalHeader.propTypes = {
  className: PropTypes.string,
  toggleNotes: PropTypes.func,
};

export default JournalHeader;
