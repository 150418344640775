import { REDUX_STATUS } from 'lib/constants';
import { GET_PROGRESS_REPORT, GET_PERCENTAGE_REPORT } from './actions';

const initialState = {
  progress: {
    groupId: undefined,
    data: undefined,
    status: REDUX_STATUS.IDLE,
    error: undefined,
  },
  percentage: {
    groupId: undefined,
    data: undefined,
    status: REDUX_STATUS.IDLE,
    error: undefined,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PROGRESS_REPORT.REQUEST:
      return {
        ...state,
        progress: {
          ...state.progress,
          status: REDUX_STATUS.PENDING,
          error: undefined,
        },
      };
    case GET_PROGRESS_REPORT.SUCCESS: {
      if (action.meta.cached) {
        return {
          ...state,
          progress: {
            ...state.progress,
            status: REDUX_STATUS.SUCCESS,
          },
        };
      }

      return {
        ...state,
        progress: {
          ...state.progress,
          groupId: action.meta.groupId,
          data: action.payload,
          status: REDUX_STATUS.SUCCESS,
        },
      };
    }
    case GET_PROGRESS_REPORT.ERROR:
      return {
        ...state,
        progress: {
          ...state.progress,
          status: REDUX_STATUS.ERROR,
          error: action.payload,
        },
      };

    case GET_PERCENTAGE_REPORT.REQUEST:
      return {
        ...state,
        percentage: {
          ...state.percentage,
          status: REDUX_STATUS.PENDING,
          error: undefined,
        },
      };
    case GET_PERCENTAGE_REPORT.SUCCESS: {
      if (action.meta.cached) {
        return {
          ...state,
          percentage: {
            ...state.percentage,
            status: REDUX_STATUS.SUCCESS,
          },
        };
      }

      return {
        ...state,
        percentage: {
          ...state.percentage,
          groupId: action.meta.groupId,
          data: action.payload,
          status: REDUX_STATUS.SUCCESS,
        },
      };
    }
    case GET_PERCENTAGE_REPORT.ERROR:
      return {
        ...state,
        percentage: {
          ...state.percentage,
          status: REDUX_STATUS.ERROR,
          error: action.payload,
        },
      };

    default:
      return state;
  }
};
