import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { LOAD_CHANGE_GROUPS_DATA } from 'store/groupMemberships/actions';
import { Button, Spinner } from 'react-bootstrap';
import { REDUX_STATUS } from 'lib/constants';
import ErrorMessage from 'components/shared/ErrorMessage';
import DefaultModal from 'components/shared/Modal/DefaultModal';
import Badge from 'components/shared/Badge/Badge';

/*
 * Get a time in hh:mm:ss format from an integer of seconds
 * @param {number|string} participants
 * @param {number} [type=0] - 0: return 'this participant' or 'these participants', 1: return '1 participant' or '2 participants'
 * @returns {string} - 'this participant' or 'these participants'; '1 participant' or '2 participants'; or the string input value
*/
const participantText = (participants, type = 0) => {
  if (Number.isInteger(participants)) {
    const text = participants === 1 ? 'participant' : 'participants';
    const article = participants === 1 ? 'this' : 'these';

    if (type === 0) return `${article} ${text}`;

    return `${participants} ${text}`;
  }

  return participants;
};

function ChangeParticipantGroupModal({ groupId, isOpen, onClose, onExited, onSave, isSubmitting, participants }) {
  const { data, status, error } = useSelector((state) => state.groupMemberships.changeGroupsData);
  const { error: groupError } = useSelector((state) => state.groupShow.group || {});
  const { error: gmError } = useSelector((state) => state.groupMemberships.groupMembership || {});

  const dispatch = useDispatch();
  const [groups, currentGroup] = useMemo(() => {
    const initialGroups = data?.groups?.filter((group) => group.id !== groupId) || [];
    return [initialGroups, data?.groups?.find((group) => group.id === groupId)];
  }, [groupId, data]);
  const [filteredGroups, setFilteredGroups] = useState([]);

  const [currentStep, setCurrentStep] = useState(0);
  const [groupSearchTerm, setGroupSearchTerm] = useState('');
  const [newGroup, setNewGroup] = useState(null);
  const [newSubGroup, setNewSubGroup] = useState(null);

  useEffect(() => {
    if (isOpen) {
      dispatch(LOAD_CHANGE_GROUPS_DATA.request());
    }
  }, [isOpen, dispatch]);

  useEffect(() => {
    setFilteredGroups(groups.filter((group) => group.courseTypeId === currentGroup?.courseTypeId));
  }, [groups, currentGroup]);

  const filterGroups = ({ target }) => {
    if (!target.value || target.value.length < 3) {
      setGroupSearchTerm('');
      setFilteredGroups(groups.filter((group) => group.courseTypeId === currentGroup.courseTypeId));
      return;
    }

    setGroupSearchTerm(target.value);
    setFilteredGroups(groups.filter((group) => {
      const parsedSearchValue = target.value.split(' ');

      if (parsedSearchValue.length > 1) {
        return parsedSearchValue.every((word) => group.name.toLowerCase().includes(word.toLowerCase()));
      }

      return group.id !== groupId && group.name.toLowerCase().includes(target.value.toLowerCase());
    }));
  };

  const handleExited = () => {
    setCurrentStep(0);
    setGroupSearchTerm('');
    setNewGroup(null);
    setNewSubGroup(null);
    onExited();
  };

  const handleSelectGroup = (id) => {
    const selectedGroup = groups.find((group) => group.id === id);
    setNewGroup(selectedGroup);
    setNewSubGroup(null);
    if (!selectedGroup?.subGroups?.length) {
      setCurrentStep(2);
    } else {
      setCurrentStep(1);
    }
  };

  const handleSelectSubGroup = (id) => {
    const selectedSubGroup = newGroup.subGroups?.find((sg) => sg.id === id);
    setNewSubGroup(selectedSubGroup);
    setCurrentStep(2);
  };

  const handleNext = () => {
    if (currentStep === 1) {
      setCurrentStep(2);
      return;
    }

    // currentStep === 0
    if (newGroup?.subGroups?.length) {
      setCurrentStep(1);
    } else {
      setCurrentStep(2);
    }
  };

  const handleBack = () => {
    if (currentStep === 1) {
      setCurrentStep(0);
      return;
    }

    // currentStep === 2
    if (newGroup?.subGroups?.length) {
      setCurrentStep(1);
    } else {
      setCurrentStep(0);
    }
  };

  const handleSave = () => {
    if (!newGroup || (newGroup.subGroups?.length && !newSubGroup)) {
      return;
    }

    onSave(newGroup.id, newSubGroup?.id);
  };

  if (!currentGroup) {
    return null;
  }

  return (
    <DefaultModal
      header="Change group"
      isOpen={isOpen}
      onClose={onClose}
      onExited={handleExited}
      footerComponent={(
        <div className="d-flex w-100">
          {currentStep > 0 && (
            <Button variant="plain" onClick={handleBack} style={{ marginLeft: 'calc(var(--bs-btn-padding-x) * -1)' }}>Back</Button>
          )}
          {currentStep < 2 && (
            <Button
              variant="primary"
              onClick={handleNext}
              className="ms-auto"
              disabled={(currentStep === 0 && !newGroup) || (currentStep === 1 && !newSubGroup)}
            >
              Next
            </Button>
          )}
          {currentStep === 2 && (
            <Button variant="primary" onClick={handleSave} className="ms-auto">
              Change
              {isSubmitting && <Spinner size="sm" className="ms-1" animation="border" role="status" />}
            </Button>
          )}
        </div>
    )}
    >
      <div style={{ minHeight: '16em' }}>
        <ErrorMessage error={groupError || gmError} />

        {currentStep === 0 && ( // Select Group
          <>
            <p>
              {`Select which group you would like to move ${participantText(participants)} to:`}
            </p>

            <div>
              <input
                className="form-control form-control--search"
                type="text"
                placeholder="Start typing group name"
                onChange={filterGroups}
                disabled={status !== REDUX_STATUS.SUCCESS}
              />

              <p className="mt-3 mb-2 fw-semibold text-small text-secondary">Groups</p>

              {error && <p className="text-danger">{error}</p>}

              {!error && (
                <>
                  <ul className="list-unstyled overflow-y-auto mb-0 border-bottom" style={{ maxHeight: '10em' }}>
                    {(groupSearchTerm && filteredGroups.length === 0) && <li>No Groups to display.</li>}
                    {filteredGroups.length > 0 && filteredGroups?.map((group) => (
                      <li key={group.id}>
                        <Button variant="plain" className="w-100 px-0 text-start fw-semibold" onClick={() => handleSelectGroup(group.id)}>
                          {group.name}
                          <Badge
                            title={group.state === 'registration_open' ? 'Open' : 'Closed'}
                            variant={group.state === 'registration_open' ? 'green' : 'blue'}
                            className="ms-1"
                            style={{ fontSize: '60%' }}
                          />
                        </Button>
                      </li>
                    ))}
                  </ul>
                  <p className="mb-0 text-small text-accent-grey">Initial list contains Groups for the same Course. Use Search to find additional Groups.</p>
                </>
              )}
            </div>
          </>
        )}

        {currentStep === 1 && (
          <>
            <p>
              Select which pod within
              <br />
              <span className="text-primary fw-semibold">{newGroup.name}</span>
            </p>

            <p className="mt-3 mb-2 fw-semibold text-small text-secondary">Pods</p>

            <ul className="list-unstyled overflow-y-auto mb-0" style={{ maxHeight: '10em' }}>
              {newGroup?.subGroups?.map((subGroup) => (
                <li key={subGroup.id}>
                  <Button variant="plain" className="w-100 px-0 text-start fw-semibold" onClick={() => handleSelectSubGroup(subGroup.id)}>
                    {`${subGroup.name} (${subGroup.participantCount})`}
                  </Button>
                </li>
              ))}
            </ul>
          </>
        )}

        {currentStep === 2 && (
          <>
            <p>
              {`${participantText(participants, 1)} will be moved to the following group:`}
            </p>

            <p className="mb-1 text-primary fw-semibold">{newGroup.name}</p>

            {newSubGroup && (
              <p className="ms-4 text-primary fw-semibold">{newSubGroup.name}</p>
            )}
          </>
        )}
      </div>
    </DefaultModal>
  );
}

ChangeParticipantGroupModal.defaultProps = {
  groupId: null,
  participants: null,
  onExited: () => {},
};

ChangeParticipantGroupModal.propTypes = {
  groupId: PropTypes.number,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onExited: PropTypes.func,
  onSave: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  participants: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default ChangeParticipantGroupModal;
