import React from 'react';

function Search() {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M15.663 15.663C15.8023 15.5235 15.9678 15.4129 16.1499 15.3374C16.332 15.2619 16.5272 15.2231 16.7243 15.2231C16.9214 15.2231 17.1166 15.2619 17.2987 15.3374C17.4808 15.4129 17.6462 15.5235 17.7855 15.663L23.5605 21.438C23.842 21.7193 24.0002 22.1008 24.0003 22.4987C24.0005 22.8966 23.8426 23.2783 23.5613 23.5597C23.28 23.8412 22.8985 23.9994 22.5006 23.9996C22.1027 23.9997 21.721 23.8418 21.4395 23.5605L15.6645 17.7855C15.5251 17.6462 15.4144 17.4808 15.339 17.2987C15.2635 17.1166 15.2246 16.9214 15.2246 16.7242C15.2246 16.5271 15.2635 16.3319 15.339 16.1498C15.4144 15.9677 15.5251 15.8023 15.6645 15.663H15.663Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M9.75 18C10.8334 18 11.9062 17.7866 12.9071 17.372C13.9081 16.9574 14.8175 16.3497 15.5836 15.5836C16.3497 14.8175 16.9574 13.9081 17.372 12.9071C17.7866 11.9062 18 10.8334 18 9.75C18 8.66659 17.7866 7.5938 17.372 6.59286C16.9574 5.59193 16.3497 4.68245 15.5836 3.91637C14.8175 3.15029 13.9081 2.5426 12.9071 2.12799C11.9062 1.71339 10.8334 1.5 9.75 1.5C7.56196 1.5 5.46354 2.36919 3.91637 3.91637C2.36919 5.46354 1.5 7.56196 1.5 9.75C1.5 11.938 2.36919 14.0365 3.91637 15.5836C5.46354 17.1308 7.56196 18 9.75 18ZM19.5 9.75C19.5 12.3359 18.4728 14.8158 16.6443 16.6443C14.8158 18.4728 12.3359 19.5 9.75 19.5C7.16414 19.5 4.68419 18.4728 2.85571 16.6443C1.02723 14.8158 0 12.3359 0 9.75C0 7.16414 1.02723 4.68419 2.85571 2.85571C4.68419 1.02723 7.16414 0 9.75 0C12.3359 0 14.8158 1.02723 16.6443 2.85571C18.4728 4.68419 19.5 7.16414 19.5 9.75Z" fill="currentColor" />
    </svg>
  );
}

export default Search;
