import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { REDUX_STATUS } from 'lib/constants';
import { GroupMembershipPropType } from 'lib/propTypes';
import { buildRoutePath } from 'lib/routerHelpers';
import { REGISTRAR_USERS_GROUP_MEMBERSHIP_PATH } from 'lib/routerPaths';
import { CHANGE_PARTICIPANT_GROUP, RESET_GROUP_MEMBERSHIP } from 'store/groupMemberships/actions';
import { Button } from 'react-bootstrap';
import SanyasIcon from 'components/shared/SanyasIcon';
import ChangeParticipantGroupModal from './ChangeParticipantGroupModal';

function ChangeParticipantGroup({ participant }) {
  const { destination, status } = useSelector((state) => state.groupMemberships.groupMembership);
  const [modalOpen, setModalOpen] = useState(false);
  const buttonRef = useRef();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (status === REDUX_STATUS.SUCCESS) {
      setModalOpen(false);
    }
  }, [status, destination, history]);

  const handleModalClose = useCallback(() => {
    setModalOpen(false);
  }, []);

  const handleModalExited = useCallback(() => {
    buttonRef.current?.blur();

    if (destination) {
      history.replace(buildRoutePath(REGISTRAR_USERS_GROUP_MEMBERSHIP_PATH, { id: destination.userId, groupMembershipId: destination.id }));
      dispatch(RESET_GROUP_MEMBERSHIP.action());
    }
  }, [destination, history, dispatch]);

  const handleChangeParticipantGroup = useCallback((newGroupId, newSubGroupId) => {
    dispatch(CHANGE_PARTICIPANT_GROUP.request({
      id: participant.id,
      groupId: newGroupId,
      subGroupId: newSubGroupId,
    }));
  }, [dispatch, participant]);

  return (
    <>
      <li className="d-flex align-items-baseline mb-3 px-3 text-primary">
        <SanyasIcon name="arrowLeftRight" className="me-2" />
        <span>
          <Button ref={buttonRef} variant="link" className="d-block btn-plain p-0 text-start" onClick={() => setModalOpen(true)}>
            Change group
          </Button>
        </span>
      </li>

      <ChangeParticipantGroupModal
        key={participant.groupId}
        groupId={participant.groupId}
        isOpen={modalOpen}
        onClose={handleModalClose}
        onExited={handleModalExited}
        onSave={handleChangeParticipantGroup}
        isSubmitting={status === REDUX_STATUS.PENDING}
        participants={participant.fullName || destination.fullName}
      />
    </>
  );
}

ChangeParticipantGroup.defaultProps = {
  participant: null,
};

ChangeParticipantGroup.propTypes = {
  participant: GroupMembershipPropType,
};

export default ChangeParticipantGroup;
