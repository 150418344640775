import { fetchGet, fetchPatch, fetchPost, handleSimpleFetchError } from 'lib/apiHelpers';
import { delay, put, select, takeLatest } from 'redux-saga/effects';

import { SET_NOTIFICATION } from 'store/flashNotifications/actions';
import { CREATE_VOUCHERS, RESET_VOUCHERS, GET_FORM_DATA, GET_VOUCHERS, GET_VOUCHER_PAGES, SORT_VOUCHERS, GET_VOUCHER, REDEEM_VOUCHER, UPDATE_VOUCHER, RESTORE_VOUCHER, VOID_VOUCHER, RESEND_VOUCHER_INVITE, MARK_VOUCHER_PAYMENT_AS, UPDATE_VOUCHER_SEAT_PERMISSIONS } from 'store/vouchers/actions';

const convertFilters = (values) => ({
  with_email: values.people.map((p) => p.value),
  with_course_type: values.courseTypes,
  with_mark_training_payment_as: values.markAs.toLowerCase(),
  with_state: values.status.toLowerCase(),
  with_health_authority_and_children: values.healthAuth,
  with_notes_containing: values.notes,
  with_department_containing: values.department,
  with_service_organization_containing: values.serviceOrganization,
  with_authorizer_name_containing: values.authorizerName,
  with_authorizer_email_containing: values.authorizerEmail,
  with_created_date_on_or_after: values.date.startDate,
  with_created_date_on_or_before: values.date.endDate,
});

function* getFilteredVouchers() {
  try {
    const filters = yield select((state) => state.vouchers.filters);
    const response = yield fetchGet('/api/registrar/vouchers.json', { filterrific: convertFilters(filters) });
    yield put(GET_VOUCHERS.success(response.data, { ...response.meta, cached: false }));
  } catch (err) {
    yield handleSimpleFetchError(err, GET_VOUCHERS);
  }
}

function* getFilteredVouchersPages(action) {
  try {
    const filters = yield select((state) => state.vouchers.filters);
    const response = yield fetchGet('/api/registrar/vouchers.json', { filterrific: convertFilters(filters), page: action.payload });
    yield put(GET_VOUCHER_PAGES.success(response.data, { ...response.meta, cached: false }));
  } catch (err) {
    yield handleSimpleFetchError(err, GET_VOUCHER_PAGES);
  }
}

function* getVouchersFormData() {
  try {
    const response = yield fetchGet('/api/registrar/vouchers/form_data.json');
    yield put(GET_FORM_DATA.success(response.data, { ...response.meta, cached: false }));
  } catch (err) {
    yield handleSimpleFetchError(err, GET_FORM_DATA);
  }
}

function* sortVouchersFormData() {
  try {
    const filters = yield select((state) => state.vouchers.filters);
    const response = yield fetchGet('/api/registrar/vouchers.json', { filterrific: convertFilters(filters) });
    yield put(SORT_VOUCHERS.success(response.data, { ...response.meta, cached: false }));
  } catch (err) {
    yield handleSimpleFetchError(err, SORT_VOUCHERS);
  }
}

function* createVouchers({ payload }) {
  try {
    const { data } = yield fetchPost('/api/registrar/vouchers', payload);
    yield delay(800);
    yield put(CREATE_VOUCHERS.success(data));
    yield put(SET_NOTIFICATION.action({
      message: 'Vouchers created successfully.',
      type: 'success',
    }));
    yield put(RESET_VOUCHERS.action());
  } catch (err) {
    yield handleSimpleFetchError(err, CREATE_VOUCHERS);
  }
}

function* getVoucher({ payload }) {
  try {
    const { data } = yield fetchGet(`/api/registrar/vouchers/${payload}`);
    yield put(GET_VOUCHER.success(data));
  } catch (err) {
    yield handleSimpleFetchError(err, GET_VOUCHER);
  }
}

function* redeemVoucher({ payload }) {
  try {
    const { data } = yield fetchPatch(`/api/registrar/vouchers/${payload}/redeem`);
    yield put(UPDATE_VOUCHER.success(data));
  } catch (err) {
    yield handleSimpleFetchError(err, UPDATE_VOUCHER);
  }
}

function* restoreVoucher({ payload }) {
  try {
    const { data } = yield fetchPatch(`/api/registrar/vouchers/${payload}/restore`);
    yield put(UPDATE_VOUCHER.success(data));
  } catch (err) {
    yield handleSimpleFetchError(err, UPDATE_VOUCHER);
  }
}

function* voidVoucher({ payload }) {
  try {
    const { data } = yield fetchPatch(`/api/registrar/vouchers/${payload}/void`);
    yield put(UPDATE_VOUCHER.success(data));
  } catch (err) {
    yield handleSimpleFetchError(err, UPDATE_VOUCHER);
  }
}

function* resendVoucherInvite({ payload }) {
  try {
    const { data } = yield fetchPost(`/api/registrar/vouchers/${payload}/resend_emails`);
    yield put(UPDATE_VOUCHER.success(data));
  } catch (err) {
    yield handleSimpleFetchError(err, UPDATE_VOUCHER);
  }
}

function* markVoucherPaymentAs({ payload }) {
  try {
    const { data } = yield fetchPatch(`/api/registrar/vouchers/${payload.id}/mark_payment_as`, payload);
    yield put(UPDATE_VOUCHER.success(data));
  } catch (err) {
    yield handleSimpleFetchError(err, UPDATE_VOUCHER);
  }
}

function* updateVoucherSeatPermissions({ payload }) {
  try {
    const { data } = yield fetchPatch(`/api/registrar/vouchers/${payload.id}/update_seat_permissions`, payload);
    yield put(UPDATE_VOUCHER.success(data));
  } catch (err) {
    yield handleSimpleFetchError(err, UPDATE_VOUCHER);
  }
}

export default function* sagas() {
  yield takeLatest(GET_VOUCHERS.REQUEST, getFilteredVouchers);
  yield takeLatest(GET_VOUCHER_PAGES.REQUEST, getFilteredVouchersPages);
  yield takeLatest(GET_FORM_DATA.REQUEST, getVouchersFormData);
  yield takeLatest(SORT_VOUCHERS.REQUEST, sortVouchersFormData);
  yield takeLatest(CREATE_VOUCHERS.REQUEST, createVouchers);
  yield takeLatest(GET_VOUCHER.REQUEST, getVoucher);
  yield takeLatest(REDEEM_VOUCHER.REQUEST, redeemVoucher);
  yield takeLatest(RESTORE_VOUCHER.REQUEST, restoreVoucher);
  yield takeLatest(VOID_VOUCHER.REQUEST, voidVoucher);
  yield takeLatest(RESEND_VOUCHER_INVITE.REQUEST, resendVoucherInvite);
  yield takeLatest(MARK_VOUCHER_PAYMENT_AS.REQUEST, markVoucherPaymentAs);
  yield takeLatest(UPDATE_VOUCHER_SEAT_PERMISSIONS.REQUEST, updateVoucherSeatPermissions);
}
