import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { ComponentPropType, StylePropType } from 'lib/propTypes';
import SuperFacilitatorNav from 'components/superFacilitator/SuperFacilitatorNav';
import BaseTemplate from '../BaseTemplate';

function SuperFacilitatorTemplate({ children, id, className, style }) {
  const currentUser = useSelector((state) => state.currentUser);

  const content = currentUser?.hasSuperFacilitatorAccess ? children : (
    <div className="alert alert-danger mt-4" role="alert">
      You do not have access to this page.
    </div>
  );

  return (
    <BaseTemplate
      id={id}
      className={className}
      style={style}
      navComponent={<SuperFacilitatorNav />}
    >
      {content}
    </BaseTemplate>
  );
}

SuperFacilitatorTemplate.defaultProps = {
  id: null,
  className: null,
  style: null,
};

SuperFacilitatorTemplate.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  children: ComponentPropType.isRequired,
  style: StylePropType,
};

export default SuperFacilitatorTemplate;
