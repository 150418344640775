import React from 'react';
import { useSelector } from 'react-redux';
import { REDUX_STATUS } from 'lib/constants';
import { FACILITATOR } from 'lib/routerPaths';
import useSessionStorage from 'lib/hooks/useSessionStorage';
import { selectSortedDashboardCounts } from 'store/superFacilitator/selectors';
import { Button, Table } from 'react-bootstrap';
import SanyasIcon from 'components/shared/SanyasIcon';
import DashboardTableSkeleton from './DashboardTableSkeleton';

function DashboardTable() {
  const [sort, setSort] = useSessionStorage('dashboardSort', { key: 'name', order: 'asc' });
  const { status, taskKeys } = useSelector((state) => state.superFacilitator);
  const dashboardCounts = useSelector((state) => selectSortedDashboardCounts(state, sort));

  const sortIcon = (key) => {
    if (sort.key === key) {
      return sort.order === 'asc' ? 'sortUp' : 'sortDown';
    }
    return 'sort';
  };

  const handleSort = (key) => {
    let order = (key === 'name') ? 'asc' : 'desc';
    if (sort.key === key) {
      order = sort.order === 'asc' ? 'desc' : 'asc';
    }
    setSort({ key, order });
    document.activeElement.blur();
  };

  const handleFacilitatorClick = (id) => () => {
    window.location.href = `${FACILITATOR}?facilitator=${id}`;
  };

  if (status === REDUX_STATUS.PENDING) {
    return <DashboardTableSkeleton />;
  }

  return (
    <Table striped bordered hover responsive className="sn-table my-4">
      <thead>
        <tr>
          <th>
            <Button variant="plain" size="sm" className="w-100 p-0 fw-semibold text-start text-black" onClick={() => handleSort('name')}>
              Facilitator
              <SanyasIcon name={sortIcon('name')} className="ms-2" style={{ top: 0, width: 8 }} />
            </Button>
          </th>
          {taskKeys?.map((key) => (
            <th key={key}>
              <Button variant="plain" size="sm" className="w-100 p-0 fw-semibold text-start text-black" onClick={() => handleSort(key.toLowerCase())}>
                {key}
                <SanyasIcon name={sortIcon(key.toLowerCase())} className="ms-2" style={{ top: 0, width: 8 }} />
              </Button>
            </th>
          ))}
          <th>
            <Button variant="plain" size="sm" className="w-100 p-0 fw-semibold text-start text-black" onClick={() => handleSort('closingCount')}>
              Closing counts
              <SanyasIcon name={sortIcon('closingCount')} className="ms-2" style={{ top: 0, width: 8 }} />
            </Button>
          </th>
          <th>
            <Button variant="plain" size="sm" className="w-100 p-0 fw-semibold text-start text-black" onClick={() => handleSort('totalCount')}>
              Totals
              <SanyasIcon name={sortIcon('totalCount')} className="ms-2" style={{ top: 0, width: 8 }} />
            </Button>
          </th>
        </tr>
      </thead>
      <tbody>
        {dashboardCounts?.map((facilitator) => (
          <tr key={facilitator.id} onClick={handleFacilitatorClick(facilitator.id)}>
            <td className="text-start fw-semibold"><a className="d-block btn-plain" href={`${FACILITATOR}?facilitator=${facilitator.id}`}>{facilitator.name}</a></td>
            {taskKeys?.map((key) => (
              <td key={`${facilitator.id}-${key}`}>{facilitator[key.toLowerCase()] || '-'}</td>
            ))}
            <td>{facilitator.closingCount}</td>
            <td className="fw-semibold">{facilitator.totalCount}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

export default DashboardTable;
