import React from 'react';
import PropTypes from 'prop-types';
import CopyButton from 'components/shared/Button/CopyButton';
import SanyasIcon from 'components/shared/SanyasIcon';

function GroupNameCopyButton({ groupName, tooltip }) {
  return (
    <CopyButton
      className="hover-item"
      variant={null}
      size="sm"
      icon={null}
      result={groupName}
      buttonText={{
        Copy: <SanyasIcon name="copy" />,
        Copied: <SanyasIcon name="checkCircle" />,
      }}
      tooltip={tooltip}
    />
  );
}

GroupNameCopyButton.defaultProps = {
  groupName: null,
  tooltip: false,
};

GroupNameCopyButton.propTypes = {
  groupName: PropTypes.string,
  tooltip: PropTypes.bool,
};

export default GroupNameCopyButton;
