import { createSelector } from 'reselect';
import _orderBy from 'lodash/orderBy';

export const selectDashboardCounts = (state) => state.superFacilitator.facilitators;

export const selectSortedDashboardCounts = createSelector(
  selectDashboardCounts,
  (_, params) => params,
  (dashboardCounts, { key, order }) => _orderBy(
    dashboardCounts,
    (item) => {
      if (key === 'name') return item.name.toLowerCase();
      if (!item[key]) return 0;
      return item[key];
    },
    order,
  ),
);
