import _last from 'lodash/last';
import _sortBy from 'lodash/sortBy';
import { format, formatDistanceToNow, isSameYear, isWithinInterval, parseISO, subDays } from 'date-fns';
import { DT_MESSAGE, DT_MESSAGE_YEAR } from 'lib/dateFormats';

/**
 * The conversations are sorted by latest message first
 */
export function sortConversations(conversations) {
  return _sortBy(conversations, ((convo) => _last(_sortBy(convo.messages, ['updatedBy'])).updatedAt
  )).reverse();
}

/**
 * @return Number - the number of unread messages in this conversation
 */
export function getUnreadCount(messages, currentUserId) {
  return messages.filter((m) => m.toUserId === currentUserId && m.readAt == null).length;
}

/**
 * @param conversation object
 * @param currentUserId number
 * @returns boolean
 */
export function isConversationMember(conversation, currentUserId) {
  if (!conversation) return false;
  return conversation.messages.some((m) => m.fromUser.id === currentUserId || m.toUser.id === currentUserId);
}

export function formatDate(date) {
  const now = Date.now();
  const messageDate = parseISO(date);
  if (isWithinInterval(messageDate, { start: subDays(now, 44), end: now })) {
    return formatDistanceToNow(messageDate, { addSuffix: true });
  } if (isSameYear(now, messageDate)) {
    return format(messageDate, DT_MESSAGE);
  }
  return format(messageDate, DT_MESSAGE_YEAR);
}

export function canSendMessage({ currentUserId, conversation, groupMembership }) {
  let hasAccess = false;
  let groupMembershipCompletion = '';
  let groupState = '';

  const validGroupStates = ['in_session', 'in_extension'];
  const validCompletions = ['ready_to_go', 'in_session_logged_in', 'in_extension', 'completed'];

  const message = conversation?.messages?.[0];
  if (message) {
    const participant = message.toUser.id === currentUserId ? message.fromUser : message.toUser;
    hasAccess = isConversationMember(conversation, currentUserId);
    groupMembershipCompletion = participant.groupMembershipCompletion;
    groupState = participant.groupState;
  } else if (groupMembership) {
    hasAccess = currentUserId === groupMembership?.primaryFacilitatorId;
    groupMembershipCompletion = groupMembership.completion;
    groupState = groupMembership.group.state;
  } else {
    return false;
  }

  const hasValidGroupState = validGroupStates.includes(groupState);
  const hasValidCompletion = validCompletions.includes(groupMembershipCompletion);

  let reason = 'This conversation is closed';
  if (!hasAccess) {
    reason = 'You are not a member this conversation.';
  } else if (!hasValidGroupState) {
    reason += ', because the group is not in session.';
  } else if (!hasValidCompletion) {
    reason += ', because the participant is not active.';
  }

  return {
    hasMessageAccess: hasAccess && hasValidGroupState && hasValidCompletion,
    reason,
  };
}
