import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useWorkspace } from 'lib/hooks/useWorkspace';
import { buildRoutePath } from 'lib/routerHelpers';
import { CURRICULUM_COURSE_SHOW_PATH, FACILITATOR_COURSE_PATH } from 'lib/routerPaths';
import { ASSIGN_COURSE } from 'store/groupShow/actions';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import ExtendModal from 'components/shared/ExtendModal';
import SimpleToolTip from 'components/shared/SimpleToolTip/SimpleToolTip';

function ActionsMenu() {
  const { id: workspaceId, path: workspacePath } = useWorkspace();
  const { group, subGroups, facilitatorIds } = useSelector((state) => state.groupShow);
  const currentUser = useSelector((state) => state.currentUser);
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const courseShowPath = workspaceId === 'facilitator' ? FACILITATOR_COURSE_PATH : CURRICULUM_COURSE_SHOW_PATH;

  const openModal = useCallback(() => setIsOpen(true), []);
  const closeModal = useCallback(() => setIsOpen(false), []);

  const assignCourse = useCallback(() => {
    if (!group.id) return;
    dispatch(ASSIGN_COURSE.request(group.id));
  }, [group?.id, dispatch]);

  if (!group) return null;

  return (
    <>
      <DropdownButton align="end" size="sm" variant="outline-secondary" title="More actions">
        {workspaceId === 'registrar' && (
          <>
            {group.canManuallyAssignCourse && (
            <Dropdown.Item onClick={assignCourse}>
              Manually Assign Course
            </Dropdown.Item>
            )}

            {(group.extendable && !group.hasExtension) && <Dropdown.Item onClick={openModal}>Extend Group</Dropdown.Item>}
            {(group.extendable && group.hasExtension) && <Dropdown.Item onClick={openModal}>Update Extension</Dropdown.Item>}
          </>
        )}

        {group.courseId && (
          <Dropdown.Item
            as={Link}
            to={buildRoutePath(courseShowPath, { courseId: group.courseId })}
          >
            View Course
          </Dropdown.Item>
        )}

        {(currentUser?.hasRegistrarAccess || (currentUser?.hasFacilitatorAccess && facilitatorIds?.includes(currentUser?.id))) && (
          <>
            {subGroups.length === 0 && (
              <SimpleToolTip text="Participants must be assigned to sub-groups before the list can be downloaded." placement="left">
                <Dropdown.ItemText className="text-secondary text-nowrap">
                  Download participant list
                </Dropdown.ItemText>
              </SimpleToolTip>
            )}
            {subGroups.length > 0 && (
              <Dropdown.Item href={`${workspacePath}/groups/${group.id}/participants.csv`}>
                Download participant list
              </Dropdown.Item>
            )}
          </>
        )}
      </DropdownButton>

      {workspaceId === 'registrar' && (
        <ExtendModal closeModal={closeModal} isOpen={isOpen} />
      )}
    </>

  );
}

export default ActionsMenu;
