import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ComponentPropType } from 'lib/propTypes';
import { ICON_SIZES, SIZES } from 'lib/constants';
import { Button } from 'react-bootstrap';
import SanyasIcon from 'components/shared/SanyasIcon';
import { iconNames } from 'components/shared/SanyasIcon/icons';
import SimpleToolTip from 'components/shared/SimpleToolTip/SimpleToolTip';

const DEFAULT_BUTTON_TEXT = {
  Copy: 'Copy',
  Copied: 'Copied!',
};

function CopyButton({ result, variant, size, className, disabled, icon, iconSize, buttonText, delay, tooltip }) {
  const COPY = { ...DEFAULT_BUTTON_TEXT, ...buttonText };
  const [copyText, setCopyText] = useState(COPY.Copy);
  const [tooltipText, setTooltipText] = useState(DEFAULT_BUTTON_TEXT.Copy);

  const handleCopy = useCallback(() => {
    navigator.clipboard.writeText(result);
    setCopyText(COPY.Copied);
    setTooltipText(DEFAULT_BUTTON_TEXT.Copied);
  }, [result, COPY.Copied]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCopyText(COPY.Copy);
      setTooltipText(DEFAULT_BUTTON_TEXT.Copy);
    }, delay);

    return () => clearTimeout(timer);
  }, [copyText, COPY.Copy, delay]);

  const Component = (
    <Button variant={variant} size={size} className={className} disabled={!result || disabled} onClick={handleCopy}>
      {!!icon && (<SanyasIcon name={icon} size={iconSize} className="me-1" />)}
      {copyText}
    </Button>
  );

  if (tooltip) {
    return (
      <SimpleToolTip text={tooltipText} placement="top">
        {Component}
      </SimpleToolTip>
    );
  }

  return Component;
}

CopyButton.defaultProps = {
  result: null,
  variant: 'primary',
  size: 'md',
  className: null,
  disabled: false,
  icon: 'copy',
  iconSize: 'md',
  buttonText: {},
  delay: 1200,
  tooltip: false,
};

CopyButton.propTypes = {
  result: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.oneOf(Object.keys(SIZES)),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.oneOf(iconNames),
  iconSize: PropTypes.oneOf(Object.keys(ICON_SIZES)),
  buttonText: PropTypes.shape({
    Copy: PropTypes.oneOfType([
      PropTypes.string,
      ComponentPropType,
    ]),
    Copied: PropTypes.oneOfType([
      PropTypes.string,
      ComponentPropType,
    ]),
  }),
  delay: PropTypes.number,
  tooltip: PropTypes.bool,
};

export default CopyButton;
