import React from 'react';
import PropTypes from 'prop-types';
import { ICON_SIZES } from 'lib/constants';
import { StylePropType } from 'lib/propTypes';
import Icons from './icons';

function SanyasIcon(props) {
  const { name, className, size, style } = props;
  const IconComponent = Icons[name];

  const iconStyle = {
    lineHeight: 1,
    top: '2px',
    width: ICON_SIZES[size],
    ...style,
  };

  return (
    <span className={`position-relative d-inline-block flex-shrink-0 ${className || ''}`} style={iconStyle}>
      <IconComponent />
    </span>
  );
}

SanyasIcon.defaultProps = {
  className: undefined,
  size: 'md',
  style: null,
};

SanyasIcon.propTypes = {
  name: PropTypes.oneOf(Object.keys(Icons)).isRequired,
  className: PropTypes.string,
  size: PropTypes.oneOf(Object.keys(ICON_SIZES)),
  style: StylePropType,
};

export default SanyasIcon;
