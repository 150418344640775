import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { REDUX_STATUS } from 'lib/constants';
import { GET_VOUCHER, MARK_VOUCHER_PAYMENT_AS, REDEEM_VOUCHER, RESEND_VOUCHER_INVITE, RESTORE_VOUCHER,
  UPDATE_VOUCHER_SEAT_PERMISSIONS, VOID_VOUCHER } from 'store/vouchers/actions';
import RegistrarTemplate from 'components/templates/RegistrarTemplate';
import Button from 'react-bootstrap/Button';
import Breadcrumbs from 'components/shared/Breadcrumbs';
import CopyButton from 'components/shared/Button/CopyButton';
import ErrorMessage from 'components/shared/ErrorMessage';
import Confirmation from 'components/shared/Modal/Confirmation';
import Spinner from 'components/shared/Spinner';
import VoucherActions from 'components/registrar/VoucherActions';
import VoucherDetails from 'components/registrar/Vouchers/VoucherDetails';
import VoucherLogs from 'components/registrar/VoucherLogs';

function VouchersShowPage() {
  const { id } = useParams();
  const { data: voucher, status, error } = useSelector((state) => state.vouchers.voucher);
  const [confirmation, setConfirmation] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GET_VOUCHER.request(id));
  }, [id, dispatch]);

  const redeemAction = useCallback(() => {
    setConfirmation({
      close: () => setConfirmation(null),
      text: 'Are you sure you want to redeem this voucher?',
      action: () => dispatch(REDEEM_VOUCHER.request(id)),
    });
  }, [id, dispatch]);

  const restoreAction = useCallback(() => {
    setConfirmation({
      close: () => setConfirmation(null),
      text: 'Are you sure you want to restore this voucher?',
      action: () => dispatch(RESTORE_VOUCHER.request(id)),
    });
  }, [id, dispatch]);

  const voidAction = useCallback(() => {
    setConfirmation({
      close: () => setConfirmation(null),
      text: 'Are you sure you want to void?',
      action: () => dispatch(VOID_VOUCHER.request(id)),
    });
  }, [id, dispatch]);

  const toggleMarkTrainingPaymentAs = useCallback(() => {
    const paymentAs = voucher?.markTrainingPaymentAs;
    setConfirmation({
      close: () => setConfirmation(null),
      text: `Are you sure you want to mark payment as ${paymentAs === 'Waived' ? 'Paid' : 'Waived'}?`,
      action: () => dispatch(MARK_VOUCHER_PAYMENT_AS.request({ id, markTrainingPaymentAs: paymentAs === 'Waived' ? 'paid' : 'waived' })),
    });
  }, [id, voucher, dispatch]);

  const resendAction = useCallback(() => {
    setConfirmation({
      close: () => setConfirmation(null),
      text: 'Are you sure you want to resend email invites?',
      action: () => dispatch(RESEND_VOUCHER_INVITE.request(id)),
    });
  }, [id, dispatch]);

  const toggleOverrideSeatPermissions = useCallback(() => {
    const override = voucher?.overrideSeatPermissions;
    setConfirmation({
      close: () => setConfirmation(null),
      text: `Are you sure you want to ${override ? 'allow' : 'override'} HA seat reservations?`,
      action: () => dispatch(UPDATE_VOUCHER_SEAT_PERMISSIONS.request({ id, overrideSeatPermissions: !override })),
    });
  }, [id, voucher, dispatch]);

  const url = useMemo(() => `${voucher?.host}/register/${voucher?.orgPermalink}?voucher_code=${voucher?.code}`, [voucher]);

  return (
    <RegistrarTemplate>
      <div className="header flush">
        <Breadcrumbs
          navItems={[
            { label: 'Vouchers', href: '/registrar/vouchers' },
            { label: `Voucher for ${voucher?.email || '…'}` },
          ]}
        />

        <h1 className="mb-0">{`Voucher for ${voucher?.email || '…'}`}</h1>
      </div>

      <div className="flush px-4 my-4">
        {status === REDUX_STATUS.PENDING && <Spinner />}

        {(voucher && status !== REDUX_STATUS.PENDING) && (
          <>
            <ErrorMessage error={error} />

            <p className="lead">
              Review information about and perform actions to this voucher.
            </p>

            <div className="row">
              <div className="col-md-6">
                <VoucherDetails voucher={voucher} />

                <Button variant="dark" size="sm" onClick={resendAction}>Resend voucher invite</Button>
              </div>

              <div className="col-md-6">
                <VoucherActions>
                  <VoucherActions.ActionItem
                    title={`Mark payment as ${voucher.markTrainingPaymentAs === 'Waived' ? 'Paid' : 'Waived'}`}
                    text={`Set this voucher invite code as ${voucher.markTrainingPaymentAs === 'Waived' ? 'Paid' : 'Waived'}.`}
                    action={<Button variant="outline-success" size="sm" onClick={toggleMarkTrainingPaymentAs}>{voucher?.markTrainingPaymentAs === 'Waived' ? 'Paid' : 'Waived'}</Button>}
                  />

                  {voucher.actions?.canRedeem && (
                    <VoucherActions.ActionItem
                      title="Mark as Redeemed"
                      text="Set this voucher invite code as used."
                      action={<Button variant="outline-primary" size="sm" onClick={redeemAction}>Redeem</Button>}
                    />
                  )}

                  {voucher.actions?.canVoid && (
                    <VoucherActions.ActionItem
                      title="Mark as Voided"
                      text="Revoke this voucher invite code."
                      action={<Button variant="outline-danger" size="sm" onClick={voidAction}>Void</Button>}
                    />
                  )}

                  {voucher.actions?.canRestore && (
                    <VoucherActions.ActionItem
                      title="Restore"
                      text="Set this voucher invite code as unused."
                      action={<Button variant="outline-warning" size="sm" onClick={restoreAction}>Restore</Button>}
                    />
                  )}

                  <VoucherActions.ActionItem
                    title={`${voucher.overrideSeatPermissions ? 'Allow' : 'Override'} seat reservations`}
                    text={`Set this voucher to ${voucher.overrideSeatPermissions ? 'respect' : 'override'} HA seat reservations/blocks.`}
                    action={<Button variant="outline-secondary" size="sm" onClick={toggleOverrideSeatPermissions}>{voucher?.overrideSeatPermissions ? 'Allow' : 'Override'}</Button>}
                  />

                  {voucher.actions?.canLink && (
                    <VoucherActions.ActionItem
                      title="Copy Voucher Invite Link"
                      text="Copy a link to redeem this voucher in a browser."
                      action={<CopyButton variant="outline-secondary" size="sm" result={url} className="text-nowrap" icon={null} buttonText={{ Copy: 'Copy URL' }} />}
                    />
                  )}
                </VoucherActions>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <VoucherLogs items={voucher.logs} />
              </div>
            </div>

            {confirmation && (
              <Confirmation
                open={!!confirmation}
                close={confirmation.close}
                text={confirmation.text}
                confirmAction={confirmation.action}
                delay={800}
              />
            )}
          </>
        )}
      </div>
    </RegistrarTemplate>
  );
}

export default VouchersShowPage;
