import React from 'react';
import Table from 'react-bootstrap/Table';
import Skeleton from 'components/shared/Skeleton';

function DashboardTableSkeleton() {
  return (
    <Table striped bordered hover responsive className="sn-table my-4">
      <thead>
        <tr>
          <th className="text-start">Facilitator</th>
          <th>
            <Skeleton classes="text strong" />
          </th>
          <th>
            <Skeleton classes="text strong" />
          </th>
          <th>
            <Skeleton classes="text strong" />
          </th>
          <th>Closing counts</th>
          <th>Totals</th>
        </tr>
      </thead>
      <tbody>
        {[...Array(3)].map((_, index) => (
          <>
            {/* eslint-disable-next-line react/no-array-index-key */}
            <tr key={index}>
              <td className="text-start">
                <Skeleton classes="button" />
              </td>
              <td>
                <Skeleton classes="text" />
              </td>
              <td>
                <Skeleton classes="text " />
              </td>
              <td>
                <Skeleton classes="text" />
              </td>
              <td>
                <Skeleton classes="text strong" />
              </td>
              <td>
                <Skeleton classes="text strong" />
              </td>
            </tr>
          </>
        ))}
      </tbody>
    </Table>
  );
}

export default DashboardTableSkeleton;
