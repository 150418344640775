import React from 'react';
import { GroupPropType } from 'lib/propTypes';
import GroupSettingsForm from 'components/groups/GroupSettings/GroupSettingsForm';
import GroupSettingsActionsMenu from 'components/groups/GroupSettings/GroupSettingsActionsMenu';

function GroupSettingsTabPage({ group }) {
  return (
    <div className="row">
      <div className="col-md-8">
        <GroupSettingsForm group={group} />
      </div>

      <div className="col-md-4 col-xl-3 offset-xl-1">
        <dl className="my-3 mt-md-0 border rounded bg-light px-3 py-2">
          <dt>HA Reservations</dt>
          <dd>{group.reservationsState === 'res_active' ? 'Active' : 'Expired'}</dd>
          <dt>Total Reserved Seats</dt>
          <dd>{group.reservedSeatsCount}</dd>
        </dl>
        <div className="mt-3 mt-md-0 mb-3 border rounded py-2">
          <h4 className="px-3 py-2">Actions</h4>
          <GroupSettingsActionsMenu group={group} />
        </div>
      </div>
    </div>
  );
}

GroupSettingsTabPage.propTypes = {
  group: GroupPropType.isRequired,
};

export default GroupSettingsTabPage;
