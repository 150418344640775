import React from 'react';
import PropTypes from 'prop-types';
import _sortBy from 'lodash/sortBy';
import { ConversationPropType, UserPropType } from 'lib/propTypes';
import Alert from 'react-bootstrap/Alert';
import ConversationMessage from './ConversationMessage';

function OneOnOneConversation({ conversation, user, onEditMessage }) {
  const messages = _sortBy(conversation.messages, ['createdAt']);

  return (
    <>
      {messages.length === 0 && (
        <Alert variant="info" className="mt-3">
          No messages found in this conversation.
        </Alert>
      )}
      {messages.map((message) => (
        <ConversationMessage key={message.id} message={message} userId={user?.id} onEditMessage={onEditMessage} />
      ))}
    </>
  );
}

OneOnOneConversation.defaultProps = {
  user: null,
  onEditMessage: () => {},
};

OneOnOneConversation.propTypes = {
  conversation: ConversationPropType.isRequired,
  user: UserPropType,
  onEditMessage: PropTypes.func,
};

export default OneOnOneConversation;
