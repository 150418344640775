import React from 'react';
import PropTypes from 'prop-types';
import { FacilitationSubgroupPropType, GroupParticipationPropType } from 'lib/propTypes';
import Badge from 'components/shared/Badge/Badge';
import SubGroupMembershipRow from './SubGroupMembershipRow';

function TableSubGroup({ subGroup, groupMemberships }) {
  const subGroupMemberships = subGroup ? groupMemberships?.filter((gm) => gm.subGroupId === subGroup.id) : groupMemberships;

  return (
    <>
      <tr>
        <td colSpan={5}>
          <Badge title={`${subGroupMemberships?.length}`} pill className="me-2" />
          <span className="fw-semibold">{subGroup?.name ?? 'Unassigned'}</span>
        </td>
      </tr>

      {subGroupMemberships.length > 0 && subGroupMemberships?.map((gm) => (
        <SubGroupMembershipRow key={gm.id} groupMembership={gm} />
      ))}
    </>
  );
}

TableSubGroup.defaultProps = {
  subGroup: null,
};

TableSubGroup.propTypes = {
  subGroup: FacilitationSubgroupPropType,
  groupMemberships: PropTypes.arrayOf(GroupParticipationPropType).isRequired,
};

export default TableSubGroup;
