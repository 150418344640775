import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { AnswerPropType } from 'lib/propTypes';
import { CREATE_COMMENT } from 'store/facilitatorDashboard/actions';
import { Button, Spinner } from 'react-bootstrap';
import TextareaField from 'components/shared/FormFields/Fields/TextareaField';
import WordCount from './WordCount';
import './styles.scss';

function Comment({ taskId, subGroupId, isJournal, answer }) {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.currentUser);
  const { error } = useSelector((state) => state.facilitatorDashboard);
  const initialValues = {
    subGroupId,
    taskId,
    replyToCommentId: answer?.id,
    isJournal: isJournal || false,
    newComment: '',
  };

  const handleSubmit = useCallback((values, actions) => {
    const meta = answer ? { answer } : null;
    dispatch(CREATE_COMMENT.request(values, { ...meta, actions }));
  }, [answer, dispatch]);

  return (
    <div id="reply-container" className="reply-container col-md-12">
      <div className="border-bottom p-2">
        {`Post comment as ${currentUser?.fullName}`}
      </div>

      {error && (
        <p className="mb-1 px-2 text-small text-danger">
          {error}
        </p>
      )}

      <Formik
        key={answer?.id}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={Yup.object().shape({
          newComment: Yup.string().required('Required'),
        })}
        enableReinitialize
      >
        {({ values, errors, isSubmitting }) => (
          <Form>
            <Field
              name="newComment"
              component={TextareaField}
              placeholder="Start typing here"
              className="reply-textarea d-block p-2"
            />
            {errors.newComment && <div className="form-text px-2 text-danger">{errors.newComment}</div>}

            <div className="d-flex align-items-center border-top p-2">
              <WordCount text={values.newComment} />

              <Button
                type="submit"
                variant="primary"
                className="ms-auto"
                disabled={isSubmitting}
              >
                Reply
                {isSubmitting && <Spinner size="sm" className="ms-1" animation="border" role="status" />}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
Comment.defaultProps = {
  isJournal: false,
  answer: null,
};

Comment.propTypes = {
  taskId: PropTypes.string.isRequired,
  subGroupId: PropTypes.string.isRequired,
  isJournal: PropTypes.bool,
  answer: AnswerPropType,
};

export default Comment;
