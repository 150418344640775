import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { CommentPropType } from 'lib/propTypes';
import { UPDATE_COMMENT } from 'store/facilitatorDashboard/actions';
import Button from 'react-bootstrap/Button';
import TextareaField from 'components/shared/FormFields/Fields/TextareaField';
import WordCount from './WordCount';
import './styles.scss';

function EditCommentCard({ comment, onClose }) {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.currentUser);

  const handleSubmit = useCallback((values) => {
    onClose();
    dispatch(UPDATE_COMMENT.request(values));
  }, [onClose, dispatch]);

  return (
    <div className="reply-container col-md-12">
      <div>
        <div className="border-bottom p-2">
          {`Edit ${comment.author}’s post as ${currentUser?.fullName}`}
        </div>

        <Formik
          initialValues={{ ...comment }}
          onSubmit={handleSubmit}
          validationSchema={Yup.object().shape({
            comment: Yup.string().required('Required'),
          })}
        >
          {({ values, errors }) => (
            <Form>
              <Field
                name="comment"
                component={TextareaField}
                placeholder="Start typing here"
                className="reply-textarea d-block p-2"
              />
              {errors.comment && <div className="form-text px-2 text-danger">{errors.comment}</div>}

              <div className="d-flex align-items-center border-top p-2">
                <WordCount text={values.comment} />

                <Button type="submit" variant="primary" className="ms-auto">Save</Button>
                <Button
                  className="btn-plain ms-1"
                  aria-label="Close"
                  variant="primary"
                  onClick={() => onClose()}
                >
                  Cancel
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

EditCommentCard.defaultProps = {
  onClose: () => {},
};

EditCommentCard.propTypes = {
  comment: CommentPropType.isRequired,
  onClose: PropTypes.func,
};

export default EditCommentCard;
