import React from 'react';
import PropTypes from 'prop-types';

function ActionItem({ title, text, action }) {
  return (
    <li className="list-group-item d-flex flex-column flex-md-row py-3">
      <div className="me-auto mb-2 mb-md-0">
        <span className="fw-semibold">{title}</span>
        <div className="me-3 text-small">
          {text}
        </div>
      </div>

      <div>
        {action}
      </div>
    </li>
  );
}

ActionItem.defaultProps = {
  action: null,
};

ActionItem.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  action: PropTypes.object || PropTypes.node,
};

function ActionList({ title, children }) {
  return (
    <div className="card border">
      <div className="card-header">
        {title}
      </div>

      <ul className="list-group list-group-flush">
        {children}
      </ul>
    </div>
  );
}

ActionList.defaultProps = {
  title: 'Actions',
};

ActionList.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.element,
  ]).isRequired,
};

const Action = ActionList;

Action.ActionItem = ActionItem;

export default Action;
