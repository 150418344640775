import { asyncAction, syncAction } from 'lib/actionCreatorCreators';

export const GET_VOUCHERS = asyncAction('GET_VOUCHERS');
export const GET_VOUCHER_PAGES = asyncAction('GET_VOUCHER_PAGES');
export const GET_FORM_DATA = asyncAction('GET_FORM_DATA');
export const CREATE_VOUCHERS = asyncAction('CREATE_VOUCHERS');
export const RESET_VOUCHERS = syncAction('RESET_VOUCHERS');
export const SORT_VOUCHERS = asyncAction('SORT_VOUCHERS');

export const GET_VOUCHER = asyncAction('GET_VOUCHER');
export const UPDATE_VOUCHER = asyncAction('UPDATE_VOUCHER');
export const REDEEM_VOUCHER = asyncAction('REDEEM_VOUCHER');
export const RESTORE_VOUCHER = asyncAction('RESTORE_VOUCHER');
export const VOID_VOUCHER = asyncAction('VOID_VOUCHER');
export const RESEND_VOUCHER_INVITE = asyncAction('RESEND_VOUCHER_INVITE');
export const MARK_VOUCHER_PAYMENT_AS = asyncAction('MARK_VOUCHER_PAYMENT_AS');
export const UPDATE_VOUCHER_SEAT_PERMISSIONS = asyncAction('UPDATE_VOUCHER_SEAT_PERMISSIONS');
