import React from 'react';
import { useSelector } from 'react-redux';
import Table from 'react-bootstrap/Table';
import SimpleToolTip from 'components/shared/SimpleToolTip/SimpleToolTip';
import Skeleton from 'components/shared/Skeleton';
import FacilitatorsWorkReportsListItem from './FacilitatorsWorkReportListItem';

function FacilitatorWorkReportsList() {
  const { loading, reports } = useSelector((state) => state.superFacilitatorReport);

  if (loading && !reports.allIds.length) return <Skeleton />;

  return (
    <Table bordered hover responsive className="sn-table mt-4">
      <thead>
        <tr>
          <th>ID</th>
          <th>Status</th>
          <th>Filters</th>
          <th>Requested</th>
          <th className="text-nowrap">
            Duration
            <SimpleToolTip
              placement="top"
              text="How long this report took to generate"
            >
              <span className="fas fa-info-circle ps-1" />
            </SimpleToolTip>
          </th>
          <th className="text-end">Actions</th>
        </tr>
      </thead>

      <tbody>
        {reports.allIds.map((reportId) => (
          <FacilitatorsWorkReportsListItem key={reportId} itemId={reportId} />
        ))}
      </tbody>
    </Table>
  );
}

export default FacilitatorWorkReportsList;
