import React from 'react';

function ChevronRight() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M6.969 2.46906C7.03867 2.39921 7.12143 2.3438 7.21255 2.30599C7.30366 2.26818 7.40135 2.24872 7.5 2.24872C7.59865 2.24872 7.69633 2.26818 7.78745 2.30599C7.87857 2.3438 7.96133 2.39921 8.031 2.46906L17.031 11.4691C17.1008 11.5387 17.1563 11.6215 17.1941 11.7126C17.2319 11.8037 17.2513 11.9014 17.2513 12.0001C17.2513 12.0987 17.2319 12.1964 17.1941 12.2875C17.1563 12.3786 17.1008 12.4614 17.031 12.5311L8.031 21.5311C7.89017 21.6719 7.69916 21.751 7.5 21.751C7.30083 21.751 7.10983 21.6719 6.969 21.5311C6.82817 21.3902 6.74905 21.1992 6.74905 21.0001C6.74905 20.8009 6.82817 20.6099 6.969 20.4691L15.4395 12.0001L6.969 3.53106C6.89915 3.46139 6.84374 3.37863 6.80593 3.28751C6.76812 3.19639 6.74866 3.09871 6.74866 3.00006C6.74866 2.90141 6.76812 2.80373 6.80593 2.71261C6.84374 2.62149 6.89915 2.53873 6.969 2.46906Z" fill="currentColor" />
    </svg>
  );
}

export default ChevronRight;
