import React from 'react';

function FlagFill() {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M5.25 1.5C5.44891 1.5 5.63968 1.57902 5.78033 1.71967C5.92098 1.86032 6 2.05109 6 2.25V21.75C6 21.9489 5.92098 22.1397 5.78033 22.2803C5.63968 22.421 5.44891 22.5 5.25 22.5C5.05109 22.5 4.86032 22.421 4.71967 22.2803C4.57902 22.1397 4.5 21.9489 4.5 21.75V2.25C4.5 2.05109 4.57902 1.86032 4.71967 1.71967C4.86032 1.57902 5.05109 1.5 5.25 1.5Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M5.643 3.837C7.1025 2.8635 8.022 2.25 9.75 2.25C10.7295 2.25 11.4585 2.7375 11.9925 3.093L12.0405 3.126C12.627 3.516 13.0095 3.75 13.5 3.75C13.752 3.75 14.034 3.687 14.3805 3.561C14.6814 3.44678 14.9781 3.32169 15.27 3.186C15.357 3.1455 15.4455 3.1065 15.54 3.066C16.395 2.6835 17.457 2.25 18.75 2.25C18.9489 2.25 19.1397 2.32902 19.2803 2.46967C19.421 2.61032 19.5 2.80109 19.5 3V12C19.5 12.1989 19.421 12.3897 19.2803 12.5303C19.1397 12.671 18.9489 12.75 18.75 12.75C17.793 12.75 16.98 13.065 16.149 13.4355L15.9105 13.5405C15.5805 13.6905 15.231 13.848 14.8935 13.971C14.4489 14.1448 13.9772 14.2393 13.5 14.25C12.5205 14.25 11.7915 13.7625 11.2575 13.407L11.2095 13.374C10.623 12.984 10.2405 12.75 9.75 12.75C8.5005 12.75 7.923 13.119 6.4155 14.124C6.30256 14.1992 6.17135 14.2423 6.03584 14.2488C5.90033 14.2552 5.7656 14.2248 5.646 14.1608C5.5264 14.0968 5.42641 14.0015 5.35667 13.8851C5.28694 13.7688 5.25007 13.6357 5.25 13.5V4.5C5.25006 4.37649 5.28063 4.2549 5.33898 4.14604C5.39734 4.03719 5.48167 3.94443 5.5845 3.876L5.6445 3.837H5.643Z" fill="currentColor" />
    </svg>
  );
}

export default FlagFill;
