import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { AnswerPropType } from 'lib/propTypes';
import { buildRoutePath } from 'lib/routerHelpers';
import { FACILITATOR_JOURNAL_ANSWERS_PATH } from 'lib/routerPaths';
import { sentenceCase } from 'lib/utils';
import { Button } from 'react-bootstrap';
import SanyasIcon from 'components/shared/SanyasIcon';
import Badge from 'components/shared/Badge/Badge';
import JournalPreviewCard from 'components/shared/Comments/JournalPreviewCard';
import PostFlag from 'components/shared/Comments/PostFlag';
import NotificationDot from 'components/shared/NotificationDot';
import QuicknotesPopover from '../QuicknotesPopover';
import Prioritized from '../Prioritized';
import './JournalRow.scss';

function AnswerRow({ answer, internalName, journalTaskId }) {
  const { subGroupId, taskId, id } = useParams();
  const currentUser = useSelector((state) => state.currentUser);
  const { assignedFacilitatorId, triggerGmIds } = useSelector((state) => state.facilitatorDashboard.subGroups[subGroupId].journalNotifications[taskId]);
  const [showAnswer, setShowAnswer] = useState(false);
  const isAssignedFacilitationPoint = currentUser?.id === assignedFacilitatorId;

  const showNotification = triggerGmIds.includes(answer.participationId);
  const journalPath = buildRoutePath(FACILITATOR_JOURNAL_ANSWERS_PATH, { id, subGroupId, taskId, groupMembershipId: answer.participationId });

  return (
    <>
      <tr key={answer.id} className="text-small">
        <td className="pe-0 align-content-center">
          <div className="d-flex align-items-center">
            <div className="pe-1" style={{ minWidth: 12 }}>
              {showNotification && (
                <NotificationDot age={answer.notificationPriority} />
              )}
            </div>
            {answer.flagged && <PostFlag key={journalTaskId} flag={answer.flag} />}
          </div>
        </td>
        <td className="pe-0 align-content-center">
          <Badge
            variant={internalName.toLowerCase()}
            title={internalName}
          />
          {/* styles to replicate Badge font-size and padding for alignment */}
          <strong
            className="d-inline-block ms-1 text-accent-storm"
            style={{ fontSize: '0.75em', fontWeight: 700, paddingBlock: '0.5em' }}
          >
            {answer.isPrimary ? 'P' : 'S'}
          </strong>
        </td>
        <td className="pe-0 text-nowrap align-content-center">
          <Link className="btn-plain" to={journalPath}>
            {answer.fullName}
          </Link>
        </td>
        <td className="pe-0 align-content-center">{formatDistanceToNow(parseISO(answer.createdAt), { addSuffix: true })}</td>
        <td className="pe-0 align-content-center">{`${sentenceCase(answer.completion)} ${answer.percentageDoneCourse}%`}</td>
        <td>
          <div className="d-flex justify-content-evenly">
            {isAssignedFacilitationPoint && (
              <>
                <QuicknotesPopover note={answer.note} />
                <Prioritized
                  note={answer.note || { subGroupId, taskId, groupMembershipId: answer.participationId }}
                  assigned={isAssignedFacilitationPoint}
                  variant={null}
                  className="journal-row-buttons border-0 me-4"
                  iconSize="xl"
                />
              </>
            )}

            <Link className="pt-1 text-decoration-none text-uppercase" to={journalPath}>
              View
            </Link>
            <Button variant={null} className="journal-row-buttons text-accent-land" onClick={() => setShowAnswer(!showAnswer)}>
              <SanyasIcon size="xl" name="chevronDown" />
            </Button>
          </div>
        </td>
      </tr>
      {showAnswer && (
        <tr>
          <td style={{ width: '100%' }} className="pe-0" colSpan="6">
            <JournalPreviewCard answer={answer} />
          </td>
        </tr>
      )}
    </>
  );
}

AnswerRow.propTypes = {
  answer: AnswerPropType.isRequired,
  internalName: PropTypes.string.isRequired,
  journalTaskId: PropTypes.string.isRequired,
};

export default AnswerRow;
