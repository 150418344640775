import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { REDUX_STATUS } from 'lib/constants';
import { buildRoutePath } from 'lib/routerHelpers';
import { REGISTRAR_GROUP_REPORTS_PATH } from 'lib/routerPaths';
import { GET_PROGRESS_REPORT } from 'store/groupReports/actions';
import { Spinner } from 'react-bootstrap';
import ErrorMessage from 'components/shared/ErrorMessage';
import SanyasIcon from 'components/shared/SanyasIcon';
import ProgressTable from 'components/reporting/Groups/Progress/ProgressTable';

function ProgressTabPage() {
  const { id } = useParams();
  const { status, error } = useSelector((state) => state.groupReports.progress);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GET_PROGRESS_REPORT.request({ id }));
  }, [id, dispatch]);

  return (
    <>
      <div className="d-flex align-items-center mb-3">
        <h2 className="mb-0">
          Participants Task Progress Report
        </h2>

        {status === REDUX_STATUS.PENDING && <Spinner animation="border" size="sm" variant="primary" className="ms-2 fw-normal" />}

        <Link to={buildRoutePath(REGISTRAR_GROUP_REPORTS_PATH, { id })} className="d-flex align-items-center ms-auto btn btn-plain btn-sm">
          <SanyasIcon name="chevronLeft" className="pe-1" />
          More Reports For This Group
        </Link>
      </div>

      <ErrorMessage error={error} />

      <ProgressTable key={id} />
    </>
  );
}

export default ProgressTabPage;
