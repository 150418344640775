import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Route, Switch, useLocation } from 'react-router-dom';
import _throttle from 'lodash/throttle';
import { getSearchParams } from 'lib/routerHelpers';
import { FACILITATOR_CONVERSATION_PATH, FACILITATOR_MESSAGES_NEW_PATH, FACILITATOR_MESSAGES_PATH } from 'lib/routerPaths';
import { GET_SUB_GROUPS } from 'store/messages/actions';
import SanyasIcon from 'components/shared/SanyasIcon';
import FacilitatorTemplate from 'components/templates/FacilitatorTemplate';
import MessagesList from 'components/shared/Messages/MessagesList';
import ParticipantHeader from 'components/shared/Messages/Show/ParticipantHeader';
import MessagesNewPage from './Messages/MessagesNewPage';
import MessagesShowPage from './Messages/MessagesShowPage';

function MessagesPage() {
  const location = useLocation();
  const dispatch = useDispatch();
  const availableSubGroups = useSelector((state) => state.messages.compose.availableSubGroups);
  const headerRef = useRef(null);
  const sidebarRef = useRef(null);
  const initBaseHeight = window.innerHeight - (document.getElementById('react-root')?.offsetTop || 0);
  const [baseHeight, setBaseHeight] = useState(initBaseHeight);

  const throttledScroll = useMemo(() => _throttle(() => {
    if (!sidebarRef.current) return;

    const maxHeight = window.innerHeight - (headerRef.current?.offsetHeight || 0);
    const newHeight = `${Math.min(maxHeight, baseHeight + window.scrollY)}px`;
    if (sidebarRef.current.style.height !== newHeight) {
      sidebarRef.current.style.height = newHeight;
    }
  }, 150), [baseHeight]);

  const handleScroll = useCallback(throttledScroll, [throttledScroll]);

  useEffect(() => {
    dispatch(GET_SUB_GROUPS.request());
  }, [location.pathname, dispatch]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  useEffect(() => {
    setBaseHeight(initBaseHeight - (headerRef.current?.offsetHeight || 0));
  }, [initBaseHeight, headerRef]);

  return (
    <FacilitatorTemplate className="messages-page">
      <div ref={headerRef} className="row sticky-top bg-white">
        <div className="col-4 border-bottom border-end p-0">
          <div className="d-flex align-items-center py-4 px-4">
            <h2 className="mb-0">
              <Link className="text-black text-decoration-none" to={FACILITATOR_MESSAGES_PATH}>
                <SanyasIcon name="envelope" className="me-2 text-warning" />
                Messages
              </Link>
            </h2>

            {availableSubGroups?.length > 0 && (
              <div className="ms-auto">
                <Link className="btn btn-outline-primary btn-sm ms-1 text-nowrap" to={FACILITATOR_MESSAGES_NEW_PATH}>New message</Link>
              </div>
            )}
          </div>
        </div>
        <div className="col-8 d-flex align-items-center border-bottom py-2">
          <ParticipantHeader />
        </div>
      </div>

      <div className="row">
        <div className="col-4 border-end p-0">
          <div
            ref={sidebarRef}
            className="overflow-auto position-sticky"
            style={{ height: `${baseHeight}px`, top: `${(headerRef.current?.offsetHeight || 0)}px`, transition: 'height 0.15s ease-in' }}
          >
            <MessagesList />
          </div>
        </div>
        <div className="col-8 bg-light">
          <Switch>
            <Route path={FACILITATOR_MESSAGES_NEW_PATH}>
              <MessagesNewPage userId={getSearchParams(location.search, 'user_id', { numeric: true })} />
            </Route>
            <Route path={FACILITATOR_CONVERSATION_PATH}>
              <MessagesShowPage />
            </Route>
            <Route path="*">
              <div className="position-relative h-100">
                <div className="position-absolute top-50 start-50 translate-middle">
                  <div className="bg-white p-5 rounded-circle shadow">
                    <SanyasIcon name="inbox" size="4x" className="text-primary" />
                  </div>
                </div>
              </div>
            </Route>
          </Switch>
        </div>
      </div>
    </FacilitatorTemplate>
  );
}

export default MessagesPage;
