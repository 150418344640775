import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ConversationPropType } from 'lib/propTypes';
import { UPDATE_CONVERSATION } from 'store/messages/actions';
import { Button } from 'react-bootstrap';
import SanyasIcon from 'components/shared/SanyasIcon';
import CopyButton from 'components/shared/Button/CopyButton';
import { getUnreadCount, isConversationMember } from '../utils';
import SubjectField from './SubjectField';

function ConversationHeader({ conversation }) {
  const currentUserId = useSelector((state) => state.currentUser.id);
  const [editingSubject, setEditingSubject] = useState(false);
  const dispatch = useDispatch();
  const isRead = getUnreadCount(conversation.messages, currentUserId) === 0;
  const hasAccess = isConversationMember(conversation, currentUserId);

  const handleUpdateFlag = () => {
    dispatch(UPDATE_CONVERSATION.request({ ...conversation, isFlagged: !conversation.isFlagged }));
  };

  return (
    <div className="d-flex align-items-center">
      <div className="col-7" style={{ marginLeft: -4 }}>
        {editingSubject ? (
          <SubjectField onClose={() => setEditingSubject(false)} />
        ) : (
          <Button
            variant="plain"
            className="w-100 p-1 fw-semibold text-start text-decoration-none"
            style={{ letterSpacing: 0.6, '--bs-btn-color': 'var(--bs-black)' }}
            onClick={() => setEditingSubject(true)}
          >
            {conversation.subject}
          </Button>
        )}
      </div>

      {!conversation.isAnnouncement && (
        <div className="ms-auto">
          <Button variant="plain" size="sm" onClick={handleUpdateFlag} disabled={!hasAccess}>
            <SanyasIcon name={conversation.isFlagged ? 'flagFill' : 'flag'} className={`me-1 ${conversation.isFlagged ? 'text-warning' : ''}`} />
            Flag
          </Button>

          <CopyButton variant="plain" size="sm" icon="link" buttonText={{ Copy: 'Copy link' }} result={window.location.href} />

          {hasAccess && (
            <span className={`d-inline-block px-2 py-1 text-small ${isRead ? 'text-primary' : ''}`}>
              <SanyasIcon name={`${isRead ? 'checkCircle' : 'square'}`} className="me-1" />
              Read
            </span>
          )}
        </div>
      )}
    </div>
  );
}

ConversationHeader.propTypes = {
  conversation: ConversationPropType.isRequired,
};

export default ConversationHeader;
