import React from 'react';
import { useSelector } from 'react-redux';
import { Alert, Table } from 'react-bootstrap';
import PercentageTableRow from './PercentageTableRow';

function PercentageTable() {
  const { data } = useSelector((state) => state.groupReports.percentage);
  const { participantIds, participants } = data || {};

  if (!data) return null;

  if (!participantIds.length) {
    return (
      <Alert variant="info">No participants have started this course yet.</Alert>
    );
  }

  return (
    <>
      <p>
        <strong>{`${data.groupPercentageDone}%`}</strong>
        {' '}
        {`of the participants${data.isGroupClosed ? '' : ' have'} finished this course.`}
      </p>

      <div className="table-responsive" style={{ maxHeight: 'calc(100vh - 1rem)' }}>
        <Table striped className="sn-table sticky-header mb-0 text-small">
          <thead>
            <tr>
              <th><span className="sr-only">Participant</span></th>
              <th>Current Module</th>
              <th>
                {data.groupShowPassFail ? 'Pass / Fail' : 'Percentage Complete'}
              </th>
              {data.isGroupClosed && (<th>Completion Status</th>)}
            </tr>
          </thead>
          <tbody>
            {participantIds.map((participantId) => (
              <PercentageTableRow key={participantId} participant={participants[participantId]} passFail={data.groupShowPassFail} isClosed={data.isGroupClosed} />
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
}

export default PercentageTable;
