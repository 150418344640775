import React, { useLayoutEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Alert, Table } from 'react-bootstrap';
import ProgressTableRow from './ProgressTableRow';
import './styles.scss';

function ProgressTable() {
  const { data, status } = useSelector((state) => state.groupReports.progress);
  const { participantIds, participants, sectionIds, sections } = data || {};
  const tableRef = useRef();

  useLayoutEffect(() => {
    if (!tableRef.current) return;
    const newHeight = window.innerHeight - tableRef.current.getBoundingClientRect().top;
    tableRef.current.style.height = `${newHeight}px`;
  }, [status]);

  if (!data) return null;

  if (!participantIds.length) {
    return (
      <Alert variant="info">No participants have started this course yet.</Alert>
    );
  }

  return (
    <div ref={tableRef} className="table-responsive">
      <Table striped className="sn-table progress-table mb-0">
        <thead>
          <tr>
            <th><span className="sr-only">Participant</span></th>
            {sectionIds.map((sectionId, index) => {
              const bg = index % 2 ? null : 'var(--bs-sn-clr-light-green)';
              return (
                <th key={sectionId} className="p-1 text-center text-nowrap" style={{ backgroundColor: bg }}>
                  {`Module ${sections[sectionId]?.position}`}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {participantIds.map((participantId) => (
            <ProgressTableRow key={participantId} participant={participants[participantId]} />
          ))}
        </tbody>
      </Table>
    </div>
  );
}

export default ProgressTable;
