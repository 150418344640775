import { REDUX_STATUS } from 'lib/constants';
import { GET_DASHBOARD } from './actions';

const initialState = {
  status: REDUX_STATUS.IDLE,
  error: undefined,
  facilitators: [],
  taskKeys: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_DASHBOARD.REQUEST:
      return {
        ...state,
        status: REDUX_STATUS.PENDING,
      };
    case GET_DASHBOARD.SUCCESS:
      return {
        ...state,
        status: REDUX_STATUS.SUCCESS,
        facilitators: action.payload.facilitators,
        taskKeys: action.payload.taskKeys,
      };
    case GET_DASHBOARD.FAILURE:
      return {
        ...state,
        status: REDUX_STATUS.ERROR,
        error: action.payload,
      };
    default:
      return state;
  }
};
