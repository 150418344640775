import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { format, parseISO } from 'date-fns';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import _sortBy from 'lodash/sortBy';
import { MONTH_ORDINAL_DAY } from 'lib/dateFormats';
import { buildRoutePath } from 'lib/routerHelpers';
import { FACILITATOR_DISCUSSION_BOARD_COMMENTS_PATH, FACILITATOR_GROUP_SHOW_PATH, FACILITATOR_JOURNAL_PREVIEW_COMMENTS_PATH } from 'lib/routerPaths';
import { fetchSubGroupData, notificationTotalsForSubGroup } from 'store/facilitatorDashboard/selectors';
import Table from 'react-bootstrap/Table';
import Badge from 'components/shared/Badge/Badge';
import ExtendedParticipantsList from 'components/facilitator/ExtendedParticipantsList';
import FlaggedJournalList from 'components/facilitator/FlaggedJournalList';
import InsufficientParticipationList from 'components/facilitator/InsufficientParticipationList';
import NotificationBubble from 'components/facilitator/NotificationBubble';

function facilitationPointClass(journalOrDb, facilitatedTaskIds, hasFacilitationPointAssignment) {
  if (journalOrDb.journalTaskId) {
    if (hasFacilitationPointAssignment && facilitatedTaskIds.indexOf(journalOrDb.journalTaskId) < 0) {
      return 'active transparent';
    }
    return 'active';
  }
  if (hasFacilitationPointAssignment && facilitatedTaskIds.indexOf(journalOrDb.discussionBoardId) < 0) {
    return 'transparent';
  }
  return '';
}

function podTitle(beginSessionAt, closeSessionAt, extendedUntil, hasExtensionsInPod, subGroupName, facilitatorCount, groupName) {
  const groupBeginDate = format(parseISO(beginSessionAt), MONTH_ORDINAL_DAY);
  const groupCloseDate = format(parseISO(closeSessionAt), MONTH_ORDINAL_DAY);
  const groupExtendedDate = extendedUntil && format(parseISO(extendedUntil), MONTH_ORDINAL_DAY);
  const hasMultipleFacilitators = facilitatorCount > 1;

  let title;
  if (hasMultipleFacilitators) {
    title = `${groupName} - ${groupBeginDate} to ${groupCloseDate} - ${subGroupName}`;
  } else {
    title = `${groupName} - ${groupBeginDate} to ${groupCloseDate}`;
  }
  if (extendedUntil && hasExtensionsInPod) {
    title = `${title} - Extended until ${groupExtendedDate}`;
  }
  return title;
}

function SubGroup({ subGroupId }) {
  const subGroupData = useSelector((state) => fetchSubGroupData(state, subGroupId));
  const notificationTotals = useSelector((state) => notificationTotalsForSubGroup(state, subGroupId));

  const {
    groupBeginSessionAt,
    groupCloseSessionAt,
    groupExtendedUntil,
    groupId,
    groupName,
    journalNotifications,
    discussionBoardNotifications,
    subGroupName,
    facilitatorCount,
    facilitatedTaskIds,
    hasFacilitationPointAssignment,
    flaggedJournals,
    extendedParticipants,
    insufficientParticipations,
  } = subGroupData;
  const panelBodyStyles = _isEmpty(flaggedJournals) && _isEmpty(extendedParticipants) && _isEmpty(insufficientParticipations) ? { padding: 0 } : { padding: 15 };
  const orderedNotifications = _sortBy([...Object.values(discussionBoardNotifications || {}), ...Object.values(journalNotifications || {})], 'position');

  return (
    <div className="card border mt-4">
      <div className="card-header d-flex align-items-center">
        <div className="text-truncate" style={{ width: '90%' }}>
          <Link to={buildRoutePath(FACILITATOR_GROUP_SHOW_PATH, { id: groupId })} className="btn-plain">
            { podTitle(groupBeginSessionAt, groupCloseSessionAt, groupExtendedUntil, !_isEmpty(extendedParticipants), subGroupName, facilitatorCount, groupName, groupId) }
          </Link>
          <small className="text-secondary">
            {` (id: ${groupId})`}
          </small>
        </div>
        <Badge title={notificationTotals.total} variant="orange" pill className="ms-auto" />
      </div>
      <Table className="pod-stats">
        <thead>
          <tr>
            {
              orderedNotifications.map((n) => (
                <th
                  className="active"
                  key={n.journalTaskId || n.discussionBoardId}
                  title={n.name || n.discussionBoardName}
                  style={{ cursor: 'help' }}
                >
                  {n.internalName}
                </th>
              ))
            }
          </tr>
        </thead>
        <tbody>
          <tr>
            {
              _map(orderedNotifications, (n) => {
                if (n.silenceJournalNotification) {
                  return (
                    <td className="active" key={n.position}>
                      Notifications Silenced
                    </td>
                  );
                }
                if (n.journalTaskId) {
                  const journalUrl = buildRoutePath(FACILITATOR_JOURNAL_PREVIEW_COMMENTS_PATH, { id: groupId, subGroupId, taskId: n.journalTaskId });
                  return (
                    <td className={facilitationPointClass(n, facilitatedTaskIds, hasFacilitationPointAssignment)} key={n.position}>
                      <div title="Primary Journal Comments">
                        <NotificationBubble priority="high" to={journalUrl} count={n.primary.highPriorityTotal} />
                        <NotificationBubble priority="medium" to={journalUrl} count={n.primary.mediumPriorityTotal} />
                        <NotificationBubble priority="low" to={journalUrl} count={n.primary.lowPriorityTotal} />
                      </div>
                      <div title="Secondary Journal Comments" className="mt-1 darken">
                        <NotificationBubble priority="high" to={journalUrl} count={n.replies.highPriorityTotal} />
                        <NotificationBubble priority="medium" to={journalUrl} count={n.replies.mediumPriorityTotal} />
                        <NotificationBubble priority="low" to={journalUrl} count={n.replies.lowPriorityTotal} />
                      </div>
                    </td>
                  );
                }
                if (n.discussionBoardId) {
                  const dbUrl = buildRoutePath(FACILITATOR_DISCUSSION_BOARD_COMMENTS_PATH, { id: groupId, subGroupId, taskId: n.discussionBoardId });
                  return (
                    <td className={facilitationPointClass(n, facilitatedTaskIds, hasFacilitationPointAssignment)} key={n.position}>
                      <div>
                        <NotificationBubble priority="high" to={dbUrl} count={n.highPriorityTotal} />
                        <NotificationBubble priority="medium" to={dbUrl} count={n.mediumPriorityTotal} />
                        <NotificationBubble priority="low" to={dbUrl} count={n.lowPriorityTotal} />
                      </div>
                    </td>
                  );
                }
                return null;
              })
            }
          </tr>
        </tbody>
      </Table>
      <div className="card-body" style={panelBodyStyles}>
        <div className="row">
          <FlaggedJournalList groupId={groupId} subGroupId={subGroupId} journals={flaggedJournals} />
          <ExtendedParticipantsList participants={extendedParticipants} groupId={groupId} />
          <InsufficientParticipationList ips={insufficientParticipations} groupId={groupId} />
        </div>
      </div>
    </div>
  );
}

SubGroup.propTypes = {
  subGroupId: PropTypes.number.isRequired,
};

export default SubGroup;
