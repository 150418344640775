import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { GET_DASHBOARD } from 'store/superFacilitator/actions';
import SuperFacilitatorTemplate from 'components/templates/SuperFacilitatorTemplate';
import SanyasIcon from 'components/shared/SanyasIcon';
import DashboardTable from 'components/superFacilitator/DashboardTable';

function SuperFacilitatorDashboard() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GET_DASHBOARD.request());
  }, [dispatch]);

  return (
    <SuperFacilitatorTemplate className="dashboard-page">
      <div className="header flush">
        <h1 className="mb-0 text-accent-land">
          <SanyasIcon name="compass" className="me-2" />
          Lead Dashboard
        </h1>
      </div>

      <DashboardTable />
    </SuperFacilitatorTemplate>
  );
}

export default SuperFacilitatorDashboard;
