import React, { useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { REDUX_STATUS } from 'lib/constants';
import { buildRoutePath } from 'lib/routerHelpers';
import { FACILITATOR_DISCUSSION_BOARD_COMMENTS_PATH, FACILITATOR_JOURNAL_ANSWERS_PATH } from 'lib/routerPaths';
import { LOAD_ENGAGEMENTS } from 'store/user/actions';
import Spinner from 'components/shared/Spinner';
import SanyasIcon from 'components/shared/SanyasIcon';
import CopyButton from 'components/shared/Button/CopyButton';
import SubtitleDate from 'components/shared/Comments/SubtitleDate';
import GroupLink from 'components/group_memberships/GroupLink';
import GroupNameCopyButton from 'components/group_memberships/GroupNameCopyButton';

function EngagementsShowPage() {
  const { groupMembershipId } = useParams();
  const status = useSelector((state) => state.user.engagementStatus);
  const engagements = useSelector((state) => state.user.engagements);
  const groupMembership = useSelector((state) => state.groupMemberships.data?.[0]);
  const dispatch = useDispatch();
  useEffect(() => {
    if (status !== REDUX_STATUS.SUCCESS) dispatch(LOAD_ENGAGEMENTS.request({ id: groupMembershipId }));
  }, [dispatch, groupMembershipId, status]);

  const getLink = (comment) => {
    if (comment.isJournal) {
      return buildRoutePath(FACILITATOR_JOURNAL_ANSWERS_PATH, { id: comment.groupId, subGroupId: comment.subGroupId, taskId: comment.taskId, groupMembershipId });
    }

    return `${buildRoutePath(FACILITATOR_DISCUSSION_BOARD_COMMENTS_PATH, { id: comment.groupId, subGroupId: comment.subGroupId, taskId: comment.taskId })}#comment-${comment.id}`;
  };

  return (
    <>
      <div className="d-flex bg-light px-4 py-2">
        <div className="d-flex align-items-center hover-group">
          <GroupLink group={groupMembership?.group} />

          <GroupNameCopyButton groupName={groupMembership?.group?.name} tooltip />
        </div>
      </div>

      {status === REDUX_STATUS.PENDING && <Spinner />}
      {engagements?.map((comment) => (
        <div key={comment.id} className="mt-4 px-4 mb-3 w-100">

          {!comment.isReply
           && (
           <Link to={getLink(comment)} className="btn-plain">
             <SanyasIcon className="me-2" name={comment.isJournal ? 'cardText' : 'chatQuote'} />
             {comment.internalName}
             {': '}
             {comment.taskName}
           </Link>
           )}

          <div className={`mt-2 py-2 card shadow-sm rounded-0 rounded-bottom-4 rounded-end-4 col-md-8 px-4 ${comment.isReply ? 'card-reply' : 'card-post'}`}>
            {comment.comment}
            <div className="d-flex align-items-center mt-2 text-small">
              <SubtitleDate className="me-4" date={comment.createdAt} />
              <span className="text-accent-grey">{ comment.isReply ? 'Direct reply' : 'Group post'}</span>

              <Link className="btn-plain ms-auto me-4 fw-medium" to={getLink(comment)}>Go to post</Link>
              <CopyButton
                variant={null}
                size="sm"
                className="btn-plain px-0 text-accent-grey fw-medium"
                icon="link45"
                buttonText={{ Copy: 'Copy link' }}
                result={window.location.origin + getLink(comment)}
              />
            </div>

          </div>
        </div>
      ))}
    </>
  );
}

export default EngagementsShowPage;
