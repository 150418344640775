import React from 'react';

function ArrowLeftRight() {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M15.219 11.469C15.2887 11.3992 15.3714 11.3438 15.4626 11.3059C15.5537 11.2681 15.6514 11.2487 15.75 11.2487C15.8487 11.2487 15.9463 11.2681 16.0375 11.3059C16.1286 11.3438 16.2113 11.3992 16.281 11.469L20.781 15.969C20.8508 16.0387 20.9063 16.1214 20.9441 16.2126C20.9819 16.3037 21.0013 16.4014 21.0013 16.5C21.0013 16.5987 20.9819 16.6963 20.9441 16.7875C20.9063 16.8786 20.8508 16.9613 20.781 17.031L16.281 21.531C16.1402 21.6718 15.9492 21.751 15.75 21.751C15.5508 21.751 15.3598 21.6718 15.219 21.531C15.0782 21.3902 14.9991 21.1992 14.9991 21C14.9991 20.8008 15.0782 20.6098 15.219 20.469L19.1895 16.5L15.219 12.531C15.1492 12.4613 15.0937 12.3786 15.0559 12.2875C15.0181 12.1963 14.9987 12.0987 14.9987 12C14.9987 11.9014 15.0181 11.8037 15.0559 11.7126C15.0937 11.6214 15.1492 11.5387 15.219 11.469Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M3 16.5C3 16.3011 3.07902 16.1103 3.21967 15.9697C3.36032 15.829 3.55109 15.75 3.75 15.75H19.5C19.6989 15.75 19.8897 15.829 20.0303 15.9697C20.171 16.1103 20.25 16.3011 20.25 16.5C20.25 16.6989 20.171 16.8897 20.0303 17.0303C19.8897 17.171 19.6989 17.25 19.5 17.25H3.75C3.55109 17.25 3.36032 17.171 3.21967 17.0303C3.07902 16.8897 3 16.6989 3 16.5ZM8.781 2.46901C8.85084 2.53868 8.90626 2.62145 8.94407 2.71256C8.98188 2.80368 9.00134 2.90136 9.00134 3.00001C9.00134 3.09866 8.98188 3.19635 8.94407 3.28746C8.90626 3.37858 8.85084 3.46134 8.781 3.53101L4.8105 7.50001L8.781 11.469C8.85073 11.5387 8.90604 11.6215 8.94378 11.7126C8.98152 11.8037 9.00095 11.9014 9.00095 12C9.00095 12.0986 8.98152 12.1963 8.94378 12.2874C8.90604 12.3785 8.85073 12.4613 8.781 12.531C8.71127 12.6007 8.62848 12.6561 8.53737 12.6938C8.44626 12.7315 8.34861 12.751 8.25 12.751C8.15138 12.751 8.05373 12.7315 7.96262 12.6938C7.87151 12.6561 7.78873 12.6007 7.719 12.531L3.219 8.03101C3.14915 7.96134 3.09374 7.87858 3.05593 7.78746C3.01812 7.69635 2.99866 7.59866 2.99866 7.50001C2.99866 7.40136 3.01812 7.30368 3.05593 7.21256C3.09374 7.12145 3.14915 7.03868 3.219 6.96901L7.719 2.46901C7.78867 2.39917 7.87143 2.34375 7.96255 2.30594C8.05366 2.26813 8.15135 2.24867 8.25 2.24867C8.34865 2.24867 8.44633 2.26813 8.53745 2.30594C8.62857 2.34375 8.71133 2.39917 8.781 2.46901Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M3.75 7.5C3.75 7.30109 3.82902 7.11032 3.96967 6.96967C4.11032 6.82902 4.30109 6.75 4.5 6.75H20.25C20.4489 6.75 20.6397 6.82902 20.7803 6.96967C20.921 7.11032 21 7.30109 21 7.5C21 7.69891 20.921 7.88968 20.7803 8.03033C20.6397 8.17098 20.4489 8.25 20.25 8.25H4.5C4.30109 8.25 4.11032 8.17098 3.96967 8.03033C3.82902 7.88968 3.75 7.69891 3.75 7.5Z" fill="currentColor" />
    </svg>
  );
}

export default ArrowLeftRight;
