import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { buildRoutePath } from 'lib/routerHelpers';
import { MODULE_EDIT_PATH, REGISTRAR_USERS_GROUP_MEMBERSHIP_PATH } from 'lib/routerPaths';

function PercentageTable({ participant, passFail, isClosed }) {
  const { hasCurriculumAccess } = useSelector((state) => state.currentUser);

  return (
    <tr>
      <td className="text-nowrap fw-semibold">
        <Link
          to={buildRoutePath(REGISTRAR_USERS_GROUP_MEMBERSHIP_PATH, { id: participant.userId, groupMembershipId: participant.id })}
          className="btn-plain"
        >
          {participant.fullName}
        </Link>
      </td>
      <td className="text-nowrap">
        {(!participant.isCompleted && participant.activeSection) && (
          <span>
            {hasCurriculumAccess ? (
              <Link
                to={buildRoutePath(MODULE_EDIT_PATH, { courseId: participant.activeSection.courseId, sectionId: participant.activeSection.id })}
                title={participant.activeSection.name}
              >
                {`Module ${participant.activeSection.position}`}
              </Link>
            ) : (
              <span>{`Module ${participant.activeSection.position}`}</span>
            )}
          </span>
        )}
      </td>
      <td>
        {/* eslint-disable-next-line no-nested-ternary */}
        {passFail ? (participant.isPassedCourse ? 'Pass' : 'Fail') : `${participant.percentageDoneCourse}%`}
      </td>
      {isClosed && (<td>{participant.decoratedCompletion}</td>)}
    </tr>
  );
}

PercentageTable.propTypes = {
  participant: PropTypes.shape({
    id: PropTypes.number.isRequired,
    userId: PropTypes.number.isRequired,
    fullName: PropTypes.string.isRequired,
    isCompleted: PropTypes.bool,
    isPassedCourse: PropTypes.bool,
    percentageDoneCourse: PropTypes.number,
    activeSection: PropTypes.shape({
      courseId: PropTypes.number.isRequired,
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      position: PropTypes.number.isRequired,
    }),
    decoratedCompletion: PropTypes.string,
  }).isRequired,
  passFail: PropTypes.bool.isRequired,
  isClosed: PropTypes.bool.isRequired,
};

export default PercentageTable;
