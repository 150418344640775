import React from 'react';

function Inbox() {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_246_4335)">
        <path fillRule="evenodd" clipRule="evenodd" d="M5.715 6.0945C5.92558 5.83126 6.19262 5.61869 6.49639 5.47252C6.80016 5.32635 7.13289 5.2503 7.47 5.25H16.53C16.8671 5.2503 17.1998 5.32635 17.5036 5.47252C17.8074 5.61869 18.0744 5.83126 18.285 6.0945L23.835 13.032C23.9482 13.1881 23.997 13.3817 23.9712 13.5728C23.9454 13.7638 23.847 13.9376 23.6964 14.058C23.5459 14.1785 23.3547 14.2363 23.1627 14.2195C22.9706 14.2028 22.7924 14.1127 22.665 13.968L17.115 7.032C17.0449 6.94416 16.9559 6.87321 16.8546 6.8244C16.7533 6.77559 16.6424 6.75016 16.53 6.75H7.47C7.35759 6.75016 7.24665 6.77559 7.1454 6.8244C7.04414 6.87321 6.95514 6.94416 6.885 7.032L1.335 13.968C1.27542 14.0501 1.19979 14.1193 1.1127 14.1714C1.0256 14.2234 0.928844 14.2573 0.828287 14.2709C0.727731 14.2845 0.625464 14.2775 0.527678 14.2504C0.429891 14.2233 0.338618 14.1767 0.259384 14.1133C0.18015 14.0499 0.114602 13.9711 0.0667079 13.8816C0.0188139 13.7922 -0.0104299 13.6939 -0.0192536 13.5928C-0.0280773 13.4918 -0.0162972 13.3899 0.0153735 13.2935C0.0470442 13.1971 0.0979469 13.1082 0.165 13.032L5.715 6.0945Z" fill="currentColor" />
        <path fillRule="evenodd" clipRule="evenodd" d="M0.187483 13.005C0.257766 12.925 0.344279 12.8609 0.441267 12.8169C0.538255 12.7729 0.643494 12.7501 0.749983 12.75H8.99998C9.1989 12.75 9.38966 12.829 9.53031 12.9697C9.67097 13.1103 9.74998 13.3011 9.74998 13.5C9.74998 14.0967 9.98704 14.669 10.409 15.091C10.8309 15.5129 11.4032 15.75 12 15.75C12.5967 15.75 13.169 15.5129 13.591 15.091C14.0129 14.669 14.25 14.0967 14.25 13.5C14.25 13.3011 14.329 13.1103 14.4697 12.9697C14.6103 12.829 14.8011 12.75 15 12.75H23.25C23.3563 12.75 23.4614 12.7727 23.5583 12.8164C23.6552 12.8602 23.7417 12.924 23.8121 13.0037C23.8824 13.0834 23.9351 13.1772 23.9664 13.2788C23.9978 13.3804 24.0072 13.4875 23.994 13.593L23.409 18.279C23.341 18.8233 23.0764 19.3241 22.6651 19.687C22.2538 20.05 21.7241 20.2502 21.1755 20.25H2.82448C2.27592 20.2502 1.74617 20.05 1.33486 19.687C0.923545 19.3241 0.659005 18.8233 0.590983 18.279L0.00598309 13.593C-0.00728549 13.4875 0.00203541 13.3804 0.0333273 13.2787C0.0646192 13.1771 0.117167 13.0833 0.187483 13.0035V13.005ZM1.59898 14.25L2.07898 18.093C2.10168 18.2746 2.19 18.4417 2.32732 18.5627C2.46463 18.6837 2.64146 18.7503 2.82448 18.75H21.1755C21.3583 18.7499 21.5347 18.6832 21.6717 18.5622C21.8087 18.4412 21.8968 18.2744 21.9195 18.093L22.3995 14.25H15.675C15.5028 15.0977 15.0429 15.8599 14.3732 16.4073C13.7034 16.9547 12.865 17.2538 12 17.2538C11.135 17.2538 10.2965 16.9547 9.62679 16.4073C8.95703 15.8599 8.49712 15.0977 8.32498 14.25H1.59898Z" fill="currentColor" />
      </g>
      <defs>
        <clipPath id="clip0_246_4335">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Inbox;
