import React from 'react';
import Skeleton from 'components/shared/Skeleton';

function JournalAnswersSkeleton() {
  return (
    <div className="row">
      <div className="col-7 offset-2">
        <div className="card mb-3 bg-white border rounded p-3">
          <Skeleton classes="title width-full mb-1" />
          <Skeleton classes="title width-66p mb-0" />
        </div>

        <div className="card bg-white border rounded p-3">
          <Skeleton classes="title width-half mb-3" />
          <Skeleton classes="text width-full mb-1" />
          <Skeleton classes="text width-full mb-1" />
          <Skeleton classes="text width-66p mb-0" />
        </div>
      </div>

      <div className="col-3">
        <Skeleton classes="button" />

        <div className="card mt-2 bg-white border rounded p-3">
          <Skeleton classes="title width-75p mb-1" />
          <Skeleton classes="text mb-1" />
          <Skeleton classes="text" />
        </div>
      </div>
    </div>
  );
}

export default JournalAnswersSkeleton;
