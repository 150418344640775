import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import { fetchGet, fetchDownload } from 'lib/apiHelpers';
import { selectGroupedHealthAuthorities } from 'store/healthAuthorities/selectors';
import { GET_FORM_DATA, GET_VOUCHERS } from 'store/vouchers/actions';
import Spinner from 'react-bootstrap/Spinner';
import SimpleToolTip from 'components/shared/SimpleToolTip/SimpleToolTip';
import AutoSave from 'components/shared/AutoSave';
import SelectField from 'components/shared/FormFields/SelectField';
import DatePickerField from 'components/shared/FormFields/DatePickerField';
import ClearableInputField from 'components/shared/FormFields/ClearableInputField';
import FormSkeleton from './FormSkeleton';

const formatOptionLabel = (option, { context }) => {
  if (context === 'menu') return option.label;

  return (
    <span>
      {option.label}
      <span className="ps-1 text-secondary text-small">
        {`(${option.organization})`}
      </span>
    </span>
  );
};

export const PeopleFetch = (inputValue) => new Promise(
  (resolve) => {
    if (inputValue.length >= 3) {
      resolve(fetchGet('/api/registrar/vouchers/people.json', { query: inputValue }));
    } else {
      resolve([]);
    }
  },
);

export const DownloadList = () => fetchDownload('/api/registrar/vouchers.csv');

const initialFilters = {
  courseTypes: [],
  date: {
    startDate: '',
    endDate: '',
  },
  healthAuth: [],
  markAs: '',
  department: '',
  serviceOrganization: '',
  authorizerName: '',
  authorizerEmail: '',
  notes: '',
  people: [],
  status: '',
};

function FilterForm() {
  const groupedHealthAuthorities = useSelector((state) => selectGroupedHealthAuthorities(state));
  const { submitting, formData, filters } = useSelector((state) => state.vouchers);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!formData) {
      dispatch(GET_FORM_DATA.request());
    }
  }, [formData, filters, dispatch]);

  const handleSubmit = useCallback((values) => dispatch(GET_VOUCHERS.request(values)), [dispatch]);

  if (!formData) {
    return (
      <FormSkeleton />
    );
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={filters || initialFilters}
    >
      {({ handleReset }) => (
        <Form>
          <div className="row mt-4">
            <div className="col">
              <SelectField name="status" label="Status" options={formData.states} />
            </div>

            <div className="col">
              <SelectField name="markAs" label="Marked As" options={formData.markTrainingPaymentAs} />
            </div>

            <div className="col">
              <SelectField
                name="courseTypes"
                label="Course"
                options={formData.courseTypes}
                multiple
                formatOptionLabel={formatOptionLabel}
              />
            </div>
          </div>

          <div className="row">
            <div className="col">
              <SelectField
                name="people"
                label="Name or Email"
                placeholder="Filter by name or email"
                cacheOptions
                loadOptions={PeopleFetch}
                noOptionsMessage={() => 'Start typing to search...'}
                multiple
                infoText={`Vouchers can be looked up by name or email address.
                  Names are only associated with vouchers that have been marked as redeemed.
                  However, all vouchers have an email address associated with them.`}
              />
            </div>

            <div className="col">
              <SelectField
                name="healthAuth"
                label="Health Authorities"
                options={groupedHealthAuthorities}
                multiple
                formatOptionLabel={formatOptionLabel}
              />
            </div>
          </div>

          <div className="row">
            <div className="col">
              <ClearableInputField name="department" />
            </div>

            <div className="col">
              <ClearableInputField name="serviceOrganization" />
            </div>

            <div className="col">
              <ClearableInputField name="authorizerName" />
            </div>

            <div className="col">
              <ClearableInputField name="authorizerEmail" />
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-4">
              <DatePickerField
                name="date"
                label="Issued Between"
                placeholder="Select a date range"
                mode="range"
              />
            </div>

            <div className="col">
              <ClearableInputField
                name="notes"
              />
            </div>
          </div>

          <div className="row">
            <div className="col">
              <div className="d-flex justify-content-between">
                <div className="d-flex align-items-center">
                  <button type="button" onClick={handleReset} className="btn btn-outline-dark">Reset filters</button>
                  {submitting && (
                    <Spinner size="sm" className="ms-2" animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  )}
                </div>

                <SimpleToolTip
                  placement="top"
                  text="Download the current list of vouchers as a CSV file (max. 2000 vouchers)"
                >
                  <a
                    href="/api/registrar/vouchers.csv"
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-outline-success"
                  >
                    <i className="fa fa-file-csv me-2" />
                    Download as CSV
                  </a>
                </SimpleToolTip>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col my-3">
              <AutoSave debounceMs={300} />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default FilterForm;
