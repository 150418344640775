import React from 'react';
import { useSelector } from 'react-redux';
import { useWorkspace } from 'lib/hooks/useWorkspace';
import { useGroupShowContext } from 'pages/registrar/context';
import { Button, Dropdown, DropdownButton, Spinner } from 'react-bootstrap';
import DefaultModal from 'components/shared/Modal/DefaultModal';
import ChangeParticipantGroupModal from 'components/users/GroupMembershipActionsMenu/ChangeParticipantGroupModal';

function ParticipantActionRow() {
  const group = useSelector((state) => state.groupShow.group);
  const { hasRegistrarAccess } = useSelector((state) => state.currentUser) ?? {};
  const { id: workspaceId } = useWorkspace();
  const { groupId, removeUsersFromGroup, selectAllCheckboxRef, selectAllContext, selectAllParticipants, gmIds,
    isRemoving, setIsRemoving, isMoving, setIsMoving, moveParticipants, inProgress } = useGroupShowContext();

  if (!hasRegistrarAccess || workspaceId !== 'registrar') return null;

  return (
    <div className="d-flex align-items-center ms-3 mb-2">
      <input
        ref={selectAllCheckboxRef}
        name="select-all"
        type="checkbox"
        className="form-check-input me-2"
        aria-label="Select all participants"
        checked={selectAllContext}
        onChange={({ target }) => selectAllParticipants(target.checked)}
      />
      <DropdownButton size="sm" variant="outline-secondary" title="Actions">
        {group.state === 'registration_open' && (
          <Dropdown.Item onClick={() => setIsMoving(true)} disabled={!gmIds?.length}>
            Change group
          </Dropdown.Item>
        )}
        <Dropdown.Item onClick={() => setIsRemoving(true)} disabled={!gmIds?.length}>
          Remove
        </Dropdown.Item>
      </DropdownButton>

      <DefaultModal
        isOpen={isRemoving}
        onClose={() => setIsRemoving(false)}
        header="Remove from group"
        footerComponent={(
          <Button variant="primary" onClick={removeUsersFromGroup} disabled={inProgress}>
            Remove
            {inProgress && <Spinner size="sm" className="ms-1" animation="border" role="status" />}
          </Button>
        )}
      >
        {`Are you sure you want to remove ${gmIds?.length} participant${gmIds?.length !== 1 ? 's' : ''}?`}
        <br />
        Removing them will also remove their content from this group.
      </DefaultModal>

      <ChangeParticipantGroupModal
        key={groupId}
        groupId={groupId}
        isOpen={isMoving}
        onClose={() => setIsMoving(false)}
        onSave={moveParticipants}
        isSubmitting={inProgress}
        participants={gmIds?.length}
      />
    </div>

  );
}

export default ParticipantActionRow;
