import { put, select, takeLatest } from 'redux-saga/effects';
import { fetchGet, handleSimpleFetchError } from 'lib/apiHelpers';
import { GET_PERCENTAGE_REPORT, GET_PROGRESS_REPORT } from 'store/groupReports/actions';

export default function* sagas() {
  yield takeLatest(GET_PROGRESS_REPORT.REQUEST, function* getProgressReport({ payload }) {
    const { groupReports } = yield select();
    if (groupReports.progress.groupId === payload.id) {
      yield put(GET_PROGRESS_REPORT.success({}, { cached: true }));
      return;
    }

    try {
      const { data } = yield fetchGet(`/api/registrar/groups/${payload.id}/reports/progress`);
      yield put(GET_PROGRESS_REPORT.success(data, { groupId: payload.id, cached: false }));
    } catch (err) {
      yield handleSimpleFetchError(err, GET_PROGRESS_REPORT);
    }
  });

  yield takeLatest(GET_PERCENTAGE_REPORT.REQUEST, function* getPercentageReport({ payload }) {
    const { groupReports } = yield select();
    if (groupReports.percentage.groupId === payload.id) {
      yield put(GET_PERCENTAGE_REPORT.success({}, { cached: true }));
      return;
    }

    try {
      const { data } = yield fetchGet(`/api/registrar/groups/${payload.id}/reports/percentage`);
      yield put(GET_PERCENTAGE_REPORT.success(data, { groupId: payload.id, cached: false }));
    } catch (err) {
      yield handleSimpleFetchError(err, GET_PERCENTAGE_REPORT);
    }
  });
}
