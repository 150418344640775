import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link, useRouteMatch } from 'react-router-dom';
import { UserPropType } from 'lib/propTypes';
import { buildRoutePath } from 'lib/routerHelpers';
import { FACILITATOR_CONVERSATION_PATH, FACILITATOR_MESSAGES_PATH, FACILITATOR_PARTICIPANTS_CONVERSATION_PATH, FACILITATOR_PARTICIPANTS_MESSAGES_PATH } from 'lib/routerPaths';
import { trim } from 'lib/utils';
import SanyasIcon from 'components/shared/SanyasIcon';
import { formatDate, getUnreadCount } from './utils';

const MESSAGE_ROUTES = {
  [FACILITATOR_MESSAGES_PATH]: FACILITATOR_CONVERSATION_PATH,
  [FACILITATOR_CONVERSATION_PATH]: FACILITATOR_CONVERSATION_PATH,
  [FACILITATOR_PARTICIPANTS_MESSAGES_PATH]: FACILITATOR_PARTICIPANTS_CONVERSATION_PATH,
  [FACILITATOR_PARTICIPANTS_CONVERSATION_PATH]: FACILITATOR_PARTICIPANTS_CONVERSATION_PATH,
};

function MessagesListItem({ conversationId, users, active }) {
  const { path, params: { groupMembershipId } } = useRouteMatch();
  const currentUserId = useSelector((state) => state.currentUser.id);
  const conversation = useSelector((state) => state.messages.list.conversations[conversationId]);
  const lastMessage = conversation.messages[conversation.messages.length - 1];
  const unreadCount = getUnreadCount(conversation.messages, currentUserId);
  const messagePath = buildRoutePath(MESSAGE_ROUTES[path], { conversationId, groupMembershipId });

  const { userNames } = conversation.messages.reduce((r, c) => {
    // Handle both index and show message formats
    if (c.fromUserId && c.toUserId) {
      ['fromUserId', 'toUserId'].forEach((key) => {
        if (r.users.indexOf(c[key]) === -1 && c[key] !== currentUserId) {
          r.users.push(c[key]);
          r.userNames.push(users[c[key]].fullName);
        }
      });
    }
    if (c.fromUser && c.toUser) {
      ['fromUser', 'toUser'].forEach((key) => {
        if (r.users.indexOf(c[key].id) === -1 && c[key].id !== currentUserId) {
          r.users.push(c[key].id);
          r.userNames.push(c[key].fullName);
        }
      });
    }
    return r;
  }, { users: [], userNames: [] });
  const subsetOfUserNames = `${userNames
    .slice(0, Math.min(2, userNames.length - 1))
    .join(', ')}, ...`;

  return (
    <li className={`position-relative py-2 px-4 hover-group hover-highlight ${active ? 'list-item--active' : ''}`}>
      <span className="d-flex align-items-center">
        <strong className="me-auto pe-1">
          {conversation.isAnnouncement ? subsetOfUserNames : userNames.join(', ')}
        </strong>

        {conversation.isAnnouncement ? (
          <SanyasIcon name="people" className="text-info" />
        ) : (
          <span>{ unreadCount > 0 ? <SanyasIcon name="envelope" className="text-info" /> : null }</span>
        )}

        {conversation.isFlagged && <SanyasIcon name="flagFill" className="text-warning ms-1" />}
      </span>
      <span>
        <Link
          to={messagePath}
          className="stretched-link text-decoration-none"
        >
          {trim(conversation.subject, 60)}
        </Link>
      </span>
      <strong className="d-block pt-2 text-small">
        {formatDate(lastMessage.createdAt)}
      </strong>
    </li>
  );
}

MessagesListItem.defaultProps = {
  users: {},
  active: false,
};

MessagesListItem.propTypes = {
  conversationId: PropTypes.number.isRequired,
  users: PropTypes.objectOf(UserPropType),
  active: PropTypes.bool,
};

export default MessagesListItem;
