import React from 'react';

function StarFill() {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.41793 23.1645C4.83893 23.4615 4.18193 22.941 4.29893 22.2765L5.54393 15.1815L0.259433 10.1475C-0.234067 9.67652 0.0224333 8.81552 0.683933 8.72252L8.03093 7.67852L11.3069 1.18802C11.6024 0.603018 12.4019 0.603018 12.6974 1.18802L15.9734 7.67852L23.3204 8.72252C23.9819 8.81552 24.2384 9.67652 23.7449 10.1475L18.4604 15.1815L19.7054 22.2765C19.8224 22.941 19.1654 23.4615 18.5864 23.1645L11.9999 19.7805L5.41643 23.1645H5.41793Z" fill="currentColor" />
    </svg>
  );
}

export default StarFill;
